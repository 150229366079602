import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import { useHistory } from 'react-router';

class Moved extends React.Component {
    static gO (pageName) {
        const history = useHistory();
		history.push(pageName);
	}
}

export default Moved;
