/* eslint-disable no-unused-vars */

import React, {useEffect, Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import  { Redirect } from 'react-router-dom'
import { useHistory } from 'react-router-dom';
import { withRouter } from "react-router";
import DatePicker from "react-datepicker";
 
import "react-datepicker/dist/react-datepicker.css";

import { ReactComponent as Logo } from './assets/img/rajalisensi-logo-colored.svg';
import { ReactComponent as LogoHeader } from './assets/img/rajalisensi-logo-colored-panjang.svg';

import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCoffee, faExclamationCircle, faHome, faArrowUp, faArrowDown, faAngleUp, faAngleDown, faLock, faTrash, faSpinner, faShoppingCart, faAngleRight, faAngleLeft, faClock, faTimesCircle, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import { slide as Menu } from 'react-burger-menu'

import sha256 from 'crypto-js/sha256';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';
import ReactPixel from 'react-facebook-pixel';

// import NumericInput from 'react-native-numeric-input'

import Secret from './Secret';
import Moved from './Moved';

// For Images Product
import avg from './assets/img/product/avg-min.png';
import hma_box from './assets/img/hma_logo.png';
import idm_box from './assets/img/product/idm-min.png';
import windows_box from './assets/img/product/windows10-min.png';
import office_box from './assets/img/product/office-365-min.png';
import bd_box from './assets/img/product/bitdefender-min.png';
import project_box from './assets/img/product/project-min.png';
import visio_box from './assets/img/product/visio-min.png';
import bundling_office_windows from  './assets/img/product/windows-office-min.png';
import bundling_visio_project from  './assets/img/product/visio-project-min.png';
import bundling_idm_windows from  './assets/img/product/idm-windows-min.png';
import bundling_idm_office from  './assets/img/product/idm-office-min.png';

import bundling_visio_office from  './assets/img/product/visio-office-min.png';
import bundling_visio_windows from  './assets/img/product/visio-windows-min.png';
import bundling_visio_office_windows from  './assets/img/product/visio-windows-office-min.png';

import logo_rl_png from  './assets/img/logo-rl-min.png';
import support_png from  './assets/img/support.png';

import no_image_found from './assets/img/no-image.png';
import bank_badge from './assets/img/bank-badge.png';
import bank_bca from './assets/img/bank_bca.png';
import bank_mandiri from './assets/img/bank_mandiri.png';
import secure_checkout from './assets/img/secure-checkout.jpg';
import gopay from './assets/img/gopay.png';

import payment_bca from './assets/img/bank-bca-small.png';
import payment_mandiri from './assets/img/bank-mandiri-small.png';
import payment_gopay from './assets/img/gopay-logo.png';
import payment_gabungan from './assets/img/bank-mandiri-small-copy-min.png';
import payment_paypal from './assets/img/paypal-logo-preview.png';

import payment_picture from './assets/img/pay-min.png';

import seal_secure_img from './assets/img/seal_secure_id.png';
import seal_satisfaction_img from './assets/img/seal_satisfaction_id.png';
import notfound_img from './assets/img/404-min.png';

import loader_gif from './assets/img/loader.gif';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './assets/css/App.css';
import './assets/css/toogle.scss';

import $ from 'jquery';
import jQuery from 'jquery'

import ScrollToTop from './ScrollToTop';

import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";

//Setup Pixel
const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
const options = {
    autoConfig: true, 	// set pixel's autoConfig
    debug: false, 		// enable logs
};
ReactPixel.init('1759598464509481', advancedMatching, options);

const BASE_URL = "https://api.rajalisensi.com/";
const baseUrl = BASE_URL + "front/";

const buildKey = Secret.buildKeySecret;
// Call it once in your app. At the root of your app is the best place
toast.configure({draggable: false, autoClose: 1800});
var hargaJualTotal = 'Rp0';
var subHargaJualTotal = "Rp0";
var hargaJualTotalNoFormat = "";
var isCreatedCallback = false;

var hargaJualTotalWithoutKodeUnik = 0;
var totalHargaJualTotalWihtoutKodeUnik = 0;
var TotalKodeUnik = 0;

var couponValue = '';

var couponId = '';
var couponAmount = 0;
var couponType = 0;
var paymentMethod = 1;
var isMenuOpen = false;
var state = {
  menuOpen: false
};
var stateChecked = {
  checkedval : true
}

Date.prototype.addDays = function (value) {
  this.setDate(this.getDate() + value);
  return this;
};

Date.prototype.minDays = function (value) {
  this.setDate(this.getDate() - value);
  return this;
};

function handleStateChange (state) {
  state.menuOpen = state.isOpen; 

  // console.log('state.menuOpen', state.menuOpen);
}

function setStateAsync(state) {
  return new Promise((resolve) => {
    this.setState(state, resolve)
  });
}

function openChat(){
  // $crisp.push(['do', 'chat:open']);
}

function handleStateCheckedChange (checked){
  // setStateAsync({ checked })
  // React.setState({
  //   stateChecked
  // });
  stateChecked.checkedval = checked;
  console.log(stateChecked, checked);
}

function changeIsMenuOpen(){
  state.menuOpen = false;
  $('.bm-overlay').trigger('click');

  // console.log('state.menuOpen', state.menuOpen);
}

function RemoveToChartDownselling(element){
  var downselling_id = element.target.getAttribute('data-downselling_id');
  var token = element.target.getAttribute('data-token');

  RemoveToChartProcessDownSelling(downselling_id, token);
}

function RemoveToChartProcessDownSelling(downselling_id, token){
    // console.log('downselling_id', downselling_id);

    const apiUrl =  baseUrl + 'delete_downselling_from_order.php';
    const data = new FormData();

    data.set('downselling_id', downselling_id);
    data.set('token', token);

    if(typeof downselling_id != 'undefined'){
      if(downselling_id != null){

        async function fetchData(dataSend) {

          var data = await fetch(apiUrl, {
            method: 'POST',
            // eslint-disable-next-line no-use-before-define
            body: dataSend,
          }).then(res => {
            // console.log(res.clone().json());
            return res.clone().json();
          });
      
          if(data.status){
            toast.success(data.message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            window.location.reload();

          }else{
            toast.error(data.message, {
              position: toast.POSITION.BOTTOM_RIGHT
            });
          }

        }
      
        fetchData(data);
      }
    }
}

function AddToChartDownSelling(element){

  var downselling_id = element.target.getAttribute('data-downselling_id');
  var token = element.target.getAttribute('data-token');

  const apiUrl =  baseUrl + 'add_downselling_to_order.php';
  const data = new FormData();

  data.set('downselling_id', downselling_id);
  data.set('token', token);

  if(typeof downselling_id != 'undefined'){
    if(downselling_id != null){
      async function fetchData(dataSend) {

        var data = await fetch(apiUrl, {
          method: 'POST',
          // eslint-disable-next-line no-use-before-define
          body: dataSend,
        }).then(res => {
          // console.log(res.clone().json());
          return res.clone().json();
        });
    
        if(data.status){
          toast.success(data.message, {
              position: toast.POSITION.BOTTOM_RIGHT
          });

          window.location.reload();
        }else{
          toast.error(data.message, {
            position: toast.POSITION.BOTTOM_RIGHT
          });
        }

      }
    
      fetchData(data);
    }
  }
}

function RemoveToChart(element){
  var upselling_id = element.target.getAttribute('data-upselling_id');
  var token = element.target.getAttribute('data-token');

  RemoveToChartProcess(upselling_id, token);
}

function RemoveToChartProcess(upselling_id, token){
    // console.log('upselling_id', upselling_id);

    const apiUrl =  baseUrl + 'delete_upselling_from_order.php';
    const data = new FormData();

    data.set('upselling_id', upselling_id);
    data.set('token', token);

    if(typeof upselling_id != 'undefined'){
      if(upselling_id != null){

        async function fetchData(dataSend) {

          var data = await fetch(apiUrl, {
            method: 'POST',
            // eslint-disable-next-line no-use-before-define
            body: dataSend,
          }).then(res => {
            // console.log(res.clone().json());
            return res.clone().json();
          });
      
          if(data.status){
            toast.success(data.message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            window.location.reload();

          }else{
            toast.error(data.message, {
              position: toast.POSITION.BOTTOM_RIGHT
            });
          }

        }
      
        fetchData(data);
      }
    }
}

function AddToChart(element){

    var tmp_element = element;
    var upselling_id = element.target.getAttribute('data-upselling_id');
    var token = element.target.getAttribute('data-token');

    const apiUrl =  baseUrl + 'add_upselling_to_order.php';
    const data = new FormData();

    data.set('upselling_id', upselling_id);
    data.set('token', token);

    if(typeof upselling_id != 'undefined'){
      if(upselling_id != null){

        async function fetchData(dataSend) {

          var data = await fetch(apiUrl, {
            method: 'POST',
            // eslint-disable-next-line no-use-before-define
            body: dataSend,
          }).then(res => {
            // console.log(res.clone().json());
            return res.clone().json();
          });
      
          if(data.status){
            toast.success(data.message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            window.location.reload();

          }else{
            toast.error(data.message, {
              position: toast.POSITION.BOTTOM_RIGHT
            });
          }

        }
      
        fetchData(data);
      }
    }
}

function ShowMidtransPopupPay(element){
  var mid_token = element.target.getAttribute('data-mid_token');
  window.snap.pay(mid_token);
}

function parse_query_string(query) {
  var vars = query.split("&");
  var query_string = {};
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    var key = decodeURIComponent(pair[0]);
    var value = decodeURIComponent(pair[1]);
    // If first entry with this name
    if (typeof query_string[key] === "undefined") {
      query_string[key] = decodeURIComponent(value);
      // If second entry with this name
    } else if (typeof query_string[key] === "string") {
      var arr = [query_string[key], decodeURIComponent(value)];
      query_string[key] = arr;
      // If third or later entry with this name
    } else {
      query_string[key].push(decodeURIComponent(value));
    }
  }
  return query_string;
}

var defaultChecked = false;
function handleChange(checked) {
  // defaultChecked = checked;
  // this.setState({ checked });
  console.log('checked',checked);
}

function MidtransFinishView(parameters){

  document.title = 'Status Pembayaran Midtrans';

  var query = parse_query_string(parameters.parameters);


  let urlGetListProduct = baseUrl + 'get_midtrans_info.php';
  const secretHash = hmacSHA256(urlGetListProduct, buildKey).toString();
  const apiUrl =  urlGetListProduct + '?apikey=' + secretHash + '&order_id=' + query.order_id;

  const [items, setItems] = React.useState({});

  React.useEffect(() => {
    async function fetchData() {
      var data = await fetch(apiUrl).then(res => {
        // console.log(res.clone().json());
        return res.clone().json();
      });
      //console.log(data);
      setItems(data.details);
      // console.log(data.details);
    }
    fetchData();
  }, [apiUrl]);

  // console.log('items',items);

  var html = '';
  var $html_inject = '';
  $.each(items.product_list, function( index, value ) {

    var imagesProd = value.image;

    html += `<div class="child-list-product-buy">
                <div class="thumbnail">
                  <img src="`+imagesProd+`" alt="`+value.title+`"/>
                </div>
                <div class="title-and-price">
                    <h3>`+value.title+` <span>x`+value.qty+`</span></h3>
                    <h2 style="text-decoration:line-through;font-size:15px;color:#ab0000">`+value.normal_price_without_qty+`</h2>
                    <h4>`+value.special_price_without_qty+`</h4>
                </div>
              </div>`;   
              
              
    $html_inject += `
          <div class="child-summary-cont-form">
            <div class="left">
              <h3>` + value.title +` (<span class="qtySummary">x`+ value.qty +`</span>)</h3>
            </div>
            <div class="right">
              <h4>`+value.normal_price+`</h4>
              <strong>`+value.special_price+`</strong>
            </div>
          </div>
          `;
  });
  $('.list-product-buy').html(html);
  $(".parent-summary-cont-form").html($html_inject);

  return (
    <div>
        <div className="area-buy">
            <div className="buy-left-area">
              <div className="cont-area">
                <div className="cont-header" style={{'border-bottom' : '1px solid #f1f1f1'}}>
                  <h2>No. Invoice #{items.nomor_order}</h2>

                  <div className="right-secure-img">
                    <img src={seal_secure_img} alt="Transaksi Aman"/>
                    <img src={seal_satisfaction_img} alt="Jaminan Kepuasan"/>
                  </div>
                </div>
                <div className="cont-form">

                  <div className="header-finish">
                    <img src={payment_picture} alt={'Finish Order'} />
                  </div>
                 
                  <div>
                      <div className="FrameThanks" style={{'padding-top': '0px'}}>
                        <h3 className="thanks_caption">Status Pembayaran Anda</h3>
                        <div>

                        {(() => {
                        if (items.status === 'Pending') {
                          return (
                          <button className="btn btn-primary show_payment" style={{'marginBottom' : '20px'}}>
                             Menunggu Pembayaran
                          </button>
                          );
                        }else if (items.status === 'Settlement') {
                          return (
                            <button className="btn btn-success show_payment" style={{'marginBottom' : '20px'}}>
                               Pembayaran Berhasil
                            </button>
                            );
                        }else{
                          return (
                            <button className="btn btn-danger show_payment" style={{'marginBottom' : '20px'}}>
                               Pembayaran Gagal
                            </button>
                            );
                        }
                        })()}
                          
                        </div>

                        <table className="table table-hover table-invoice">
                            <tr>
                              <td>Metode Pembayaran</td>
                              <td>:</td>
                              <td style={{'font-weight' : 'bold'}}>GOPAY</td>
                            </tr>
                            <tr>
                              <td>No Invoice</td>
                              <td>:</td>
                              <td style={{'font-weight' : 'bold'}}>#{items.nomor_order}</td>
                            </tr>
                            <tr>
                              <td>Jumlah Tagihan</td>
                              <td>:</td>
                              <td style={{'font-weight' : 'bold'}}>{items.grand_total}</td>
                            </tr>
                            <tr>
                              <td>Waktu Pembelian</td>
                              <td>:</td>
                              <td style={{'font-weight' : 'bold'}}>{items.waktu_order}</td>
                            </tr>
                        </table>

                        <div className="caption_3">
                          <p>Biasanya, <span style={{'color' : 'red'}}>tidak sampai 1 menit setelah Anda melakukan pembayaran,</span> <br/>Anda akan mendapatkan email dari kami yang berisi produk yang Anda beli.</p>
                          <p><strong>Silahkan cek INBOX/SPAM di email Anda</strong>, jika dalam 1 jam Anda masih belum menerima <br/>email dari kami, segera <a href="https://wa.rajalisensi.com/" target="_blank">Hubungi Tim Kami via WhatsApp</a>.</p>
                          <p><img src={secure_checkout} alt="Transaksi Anda 100% Aman" className="secure_checkout_img" style={{'margin-top' : '30px', 'width' : '320px'}} /></p>
                        </div>
                      </div>

                      <div>{((typeof items.mid_token !== 'undefined') ? window.snap.pay(items.mid_token) : '')}</div>
                    </div>
				 
                </div>
              </div>
              <div className="cont-area" style={{'paddong-left' : '0px', 'padding-right' : '0px'}}>
                <div className="cont-header" style={{'paddong-left' : '20px', 'padding-right' : '20px'}}>
                  <h2>Pembelian Anda</h2>
                </div>
                <div className="cont-form">
                  <div className="list-product-buy">
                    {/* Digenerate Jin */}
                    <ReactPlaceholder type='text' rows={4} ready={false} style={{'padding-left' : '20px', 'padding-right' : '20px'}}>
                    </ReactPlaceholder>
                  </div>
                </div>
              </div>
            </div>
            <div className="buy-right-area">
              <div className="cont-area">
                <div className="cont-header">
                  <h2>Metode Pembayaran</h2>
                </div>
                <div className="cont-form">
                  <div className="frame-payment-selector">
                    <div className="payment-selector disabled" style={((items.payment_method === '1') ? {'background' : 'white'} : {'background' : '#f9f9f9'})}>
                      <div className="left">
                        <img src={payment_gabungan} alt={"Bank BCA"}/>
                      </div>
                      <div className="center">
                          <h2>Transfer Bank</h2>
                          <small>Diverifikasi Otomatis</small>
                      </div>
                      <div className="right">
                      {(() => {
                      if (items.payment_method === "1") {
                        return (
                          <input type="radio" name="payment_chooser" defaultValue="1" className="radio_payment_Selector" checked="checked"/>
                        );
                      }else{
                        return (
                          <input type="radio" name="payment_chooser" defaultValue="1" className="radio_payment_Selector" disabled="disabled"/>
                        );
                      }
                      })()}
                      </div>
                    </div>
                    <div style={{'display' : 'none'}}>
                      <div className="payment-selector disabled" style={((items.payment_method === '3') ? {'background' : 'white'} : {'background' : '#f9f9f9'})}>
                        <div className="left">
                          <img src={payment_gopay} alt={"GoPay"}/>
                        </div>
                        <div className="center">
                            <h2>GOPAY</h2>
                            <small>DIVERIFIKASI OTOMATIS</small>
                        </div>
                        <div className="right">
                        {(() => {
                        if (items.payment_method === "3") {
                          return (
                            <input type="radio" name="payment_chooser" defaultValue="3" className="radio_payment_Selector" checked="checked"/>
                          );
                        }else{
                          return (
                            <input type="radio" name="payment_chooser" defaultValue="3" className="radio_payment_Selector" disabled="disabled"/>
                          );
                        }
                        })()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-summary-right">
                  <div className="cont-form" style={{'marginTop': '0px'}}>
                    <div className="sub-header-cont-form">
                        <h4>Rincian Pesanan</h4>
                        <hr/>
                    </div>
                    <div className="parent-summary-cont-form">
                      {/* <!-- Digenerate Jin --> */}
                      <ReactPlaceholder type='text' rows={2} ready={false}>
                      </ReactPlaceholder>
                    </div>
                  </div>
                  <div className="cont-form">
                    <div className="summary-area">
                        <div className="child-summary">
                            <strong className="left">
                              Sub Total
                            </strong>
                            <strong className="right sub-total-payment">
                              {items.sub_total}
                            </strong>
                        </div>
                        <div className="child-summary diskon" style={((items.diskon_total !== 'Rp0,-') ? {'display' : 'block'} : {'display' : 'none'})}>
                            <strong className="left">
                              Diskon
                            </strong>
                            <strong className="right">
                              {items.diskon_total}
                            </strong>
                        </div>
                        <div className="child-summary">
                            <strong className="left">
                              Kode Unik
                            </strong>
                            <strong className="right">
                              {items.kode_unik2}
                            </strong>
                        </div>
                    </div>
                    <div className="grandtotal-area">
                      <strong className="left">
                        Grand Total
                      </strong>
                      <strong className="right total-payment">
                        {items.grand_total}
                      </strong>
                    </div>
                  </div>
                </div>
                <div className="box-white box-bantuan-shopping-cart">
                  <div className="help-cart">
                    <div className="left-info">
                      <img src={support_png} alt="Bantuan - RajaLisensi.com"/>
                    </div>
                    <div className="right-info">
                      <h1>Bantuan</h1>
                      <p>Hubungi <a href="https://wa.rajalisensi.com" target="_blank">WhatsApp</a> atau
                      email ke <a href="mailto:info@mail.rajalisensi.com">info@mail.rajalisensi.com</a> jika terjadi
                      kendala dalam proses belanja.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cont-area" style={{"background" : "#3c7d5b", "color" : "#fff", "min-height" : "auto", "display" : "flex", "justifyContent" : "flex-start", "gap" : "20px"}}>
                  <div className="tg-img">
                    <img src="https://rajalisensi.com/wp-content/uploads/2020/11/waloga-min.png" border="0"/>
                  </div>
                  <div className="tg-hd">
                      <div className="tg-head1">Mau Software Gratis?</div>
                      <div className="tg-desc">Dapatkan update software gratis dan penawaran spesial hanya melalui Grup WhatsApp kami.</div>
                      <div className="tg-btn">
                        <a href="https://chat.whatsapp.com/FnbK68DwbM2BYlP25JM3o9" target="_blank">
                          <button className="btn-tg-official">Join Sekarang</button>
                        </a>
                      </div>
                  </div>
              </div>
        </div>
        </div>

      

        
      </div>
  );
}

function SelectPaymentView(parameters){
  document.title = 'Pilih Metode Pembayaran';

  let urlGetListProduct = baseUrl + 'get_order_info.php';
  const secretHash = hmacSHA256(urlGetListProduct, buildKey).toString();
  const apiUrl =  urlGetListProduct + '?apikey=' + secretHash + '&order_id=' + parameters.order_id;

  // For Payment
  (function ($) {


    $.fn.OpenPaymentType = function(){

        $(this).on('click', function(){

            if(!$(this).hasClass('active')){
              $('.channel').removeClass('active');
              $('.payment-channel-type .page-header').removeClass('active');

              var $parent = $(this).parents('.payment-channel-type');
              var $channel_element = $parent.find('.channel');
              
              $(this).addClass('active');
              $channel_element.addClass('active');

              var position = $(this).position();
              $('#TUTUTUPAYMENTGATEWAYMODAL').animate({
                  scrollTop: position.top
              }, "slow");
            }else{
              var $parent = $(this).parents('.payment-channel-type');
              var $channel_element = $parent.find('.channel');
              
              $(this).removeClass('active');
              $channel_element.removeClass('active');

              var position = $(this).position();
              $('#TUTUTUPAYMENTGATEWAYMODAL').animate({
                  scrollTop: position.top
              }, "slow");
            }

        });

    }

      $.fn.SelectPayment = function(){

          $(this).on('click', function(){
              var $this = $(this);
              var $pay_url = $this.data('pay_url');
              var $payment_name = $this.data('payment_name');
              var $amount = $this.data('amount');

              //Implement Method Payment
              $('.pay_url').val($pay_url);
              $('.winpay-channel-selected').html($payment_name);
              $('.winpay-total-amount-pay').html($amount);

              //Hidden Parent
              $('.payment-summary-frame').slideUp();
              $('.konfirmasi_payment').slideDown();

              $('.back-step').show();
              
          });
          
      }

      $.fn.BackOneStep = function(){

          $(this).on('click', function(){
              var $this = $(this);

              $('.payment-summary-frame').slideDown();
              $('.konfirmasi_payment').slideUp();

              $('.back-step').hide();
              
          });
          
      }


      $.fn.LetsPayNow = function(){

          $(this).on('click', function(){
              var $this = $(this);
              var $pay_url = $this.data('pay_url');
              var $parent = $this.parents('.payment-summary-frame');

              //Disable Back Button
              $('.back-step').hide();
              $('.close-step').hide();

              //Flush Form
              FlushForm();

              //Submit Payment Send To WinPay
              $('form[name="tututu-payment-gateway"]').submit();
              
          });
          
      }

      $.PayNow = function(obj){

          var $paramaters = $.extend({
              order_number : '',
              user_id : '',
              amount : 0,
              item : '',
              OnWait : function(){},
              OnSuccess : function(){},
              OnError : function(){}
          }, obj);

          //Open Popup Payment
          $('#TUTUTUPAYMENTGATEWAYMODAL').modal({backdrop: 'static', keyboard: false});

          //Process Payment Request
          $.ajax({
              url : BASE_URL + 'payment-gateway/index.php',
              method : 'POST',
              dataType : 'JSON',
              data : {
                  order_number : $paramaters.order_number,
                  user_id : $paramaters.user_id,
                  amount : $paramaters.amount,
                  item : $paramaters.item
              },
              beforeSend : function(e){

                  $('.target-html-select-this-tututu-payment-gateway').html(`<div class="tututu-payment-gateway-make-secure-payment">
                  <div class="area-loading">
                    <img src="`+loader_gif+`" alt="Loading"/>
                  </div>
                  <h4>Membuat Koneksi Aman . . .</h4>
              </div>`);

                  var callbacks = $.Callbacks();
                  callbacks.add($paramaters.OnWait);
                  callbacks.fire(e);
              },
              success : function(e){

                  $('.target-html-select-this-tututu-payment-gateway').html(e.html);

                  $('.channel').SelectPayment();
                  $('.wainpay_pay_now').LetsPayNow();
                  $('.winpay_back').BackOneStep();
          
                  //Open Select Payment Method
                  $('.payment-channel-type .page-header').OpenPaymentType();

                  var callbacks = $.Callbacks();
                  callbacks.add($paramaters.OnSuccess);
                  callbacks.fire(e);
              },
              error : function(e){
                  var callbacks = $.Callbacks();
                  callbacks.add($paramaters.OnError);
                  callbacks.fire(e);
              }
          })
      }

  })(jQuery);

  function FlushForm(){
      $('form[name="tututu-payment-gateway"]').on('submit', function(e){

          e.preventDefault();

          var $this = $(this);
          var $url_ajax = $this.attr('action');

          var formData = new FormData(this);

          //Send Data Now
          $.ajax({
              url : $url_ajax,
              dataType : 'JSON',
              method : 'POST',
              data : formData,
              cache: false,
              contentType: false,
              processData: false,
              beforeSend : function(e){
                  $('.target-html-select-this-tututu-payment-gateway').html(`<div class="tututu-payment-gateway-make-secure-payment">
                  <div class="area-loading">
                    <img src="`+loader_gif+`" alt="Loading"/>
                  </div>
              </div>`);
              },
              success : function(e){
                  if(e.link != ''){
                      window.location = e.link;
                  }else{
                      $('.target-html-select-this-tututu-payment-gateway').html(e.html);
                      $('.modal-footer').html(e.footer);
                  }
                  
              },
              error : function(e){
                  // console.log('Failed Send Payment');
              }
          });

      });
  }


  $(document).ready(function(){
      $('.back-step').BackOneStep();
      $('.close-step').on('click', function(){
        return window.location.reload();
      });

      //console.log('Ready : ', $("#TUTUTUPAYMENTGATEWAYMODAL").data('bs.modal')._isShown);

      if(typeof $("#TUTUTUPAYMENTGATEWAYMODAL").data('bs.modal') === 'undefined'){
        $.ajax({
          url : apiUrl,
          dataType : 'JSON',
          method : 'GET',
          beforeSend : function(e){
  
          },
          success : function(e){
            $('#TUTUTUPAYMENTGATEWAYMODAL').modal('show');
            $.PayNow({
                'order_number' : e.details.order_id,
                OnWait : function(e){
                    // console.log('Wait');
                },
                OnSuccess : function(e){
                    // console.log('Success');
                },
                OnError : function(e){
                    // console.log(e.message);
                }
            });
          }
        });
      }

     
  });
  //End Payment

  React.useEffect(() => {
    ReactPixel.track('PageView', {});
  },[])

  return (
    <div>
      <div className="select-payment-area">
          <div className="inner">
            <FontAwesomeIcon className="IconLoading" icon={faSpinner} pulse/>
            <strong>Silahkan Pilih Metode Pembayaran</strong>
          </div>
      </div>

      <div className="modal fade" id="TUTUTUPAYMENTGATEWAYMODAL" role="dialog" aria-labelledby="TUTUTUPAYMENTGATEWAYMODAL" aria-hidden="true" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog" role="document" style={{'max-width' : '410px'}}>
              <div className="modal-content modal-content-tututu-payment-gateway">
                    <div className="modal-header">
                        <a href="javascript:;" className="back-step" style={{'display' : 'none'}}>
                          <FontAwesomeIcon icon={faArrowLeft}/>
                        </a>
                        <h5 className="modal-title" id="TUTUTUPAYMENTGATEWAYMODALLabel">
                            <img src={logo_rl_png} alt="BELIYO.COM"/>
                        </h5>
                        <a href="javascript:;" className="close-step close">
                            <FontAwesomeIcon icon={faTimesCircle}/>
                        </a>
                    </div>
                    <div className="modal-body target-html-select-this-tututu-payment-gateway">
                        {/* Di generate JIN */}
                    </div>
                    <div className="modal-footer">
                        <div className="tututu-payment-gateway-footer-copyright">
                            <p>Kami bekerja sama dengan WinPay Payment Gateway dalam proses pembayaran berikut ini, semua data transaksi yang anda masukan telah terenkripsi dengan aman oleh WinPay</p>
                        </div>
                    </div>
                </div>
          </div>
        </div>
    </div>
);

}

function FinishSellingProducts(parameters){

  document.title = 'Menunggu Pembayaran';

  let urlGetListProduct = baseUrl + 'get_order_info.php';
  const secretHash = hmacSHA256(urlGetListProduct, buildKey).toString();
  const apiUrl =  urlGetListProduct + '?apikey=' + secretHash + '&order_id=' + parameters.order_id;

  const [items, setItems] = React.useState({});
  const [paymentReceived, setPaymentReceived] = React.useState(false);

  const checkPaymentStatus = async() => {
    let urlGetPaymentStatus = baseUrl + 'get_payment_status.php';
    const secretHashPayment = hmacSHA256(urlGetPaymentStatus, buildKey).toString();
    const apiUrlPaymentStatus =  urlGetPaymentStatus + '?apikey=' + secretHashPayment + '&order_id=' + parameters.order_id;

    try{
      var resdata = await fetch(apiUrlPaymentStatus).then(res => {
        // console.log(res.clone().json());
        return res.clone().json();
      });
  
      if(resdata?.status){
        setPaymentReceived(true)
      }else{
        setPaymentReceived(false)
      }
    }catch(erx){
      setPaymentReceived(false)
    }
  }

  React.useEffect(() => {
    async function fetchData() {
      var data = await fetch(apiUrl).then(res => {
        // console.log(res.clone().json());
        return res.clone().json();
      });
      //console.log(data);
      setItems(data.details);
      // console.log(data.details);
    }
    fetchData();
  }, [apiUrl]);

  React.useEffect(() => {
    ReactPixel.track('PageView', {});

    checkPaymentStatus()
    setInterval(() => {
      checkPaymentStatus()
    }, 5000);
  }, [])

  if(typeof items.grand_total_no_format !== 'undefined'){

    $('.loading-waiting-payment').hide();
    $('.waiting-payment').show();

    //document.title = items.title;
    // ReactPixel.track('Lead', {
    //   value: items.grand_total_no_format,
    //   currency: 'IDR'
    // });
  }


  // For Payment
  (function ($) {


      $.fn.OpenPaymentType = function(){

          $(this).on('click', function(){

              if(!$(this).hasClass('active')){
                $('.channel').removeClass('active');
                $('.payment-channel-type .page-header').removeClass('active');
  
                var $parent = $(this).parents('.payment-channel-type');
                var $channel_element = $parent.find('.channel');
                
                $(this).addClass('active');
                $channel_element.addClass('active');
  
                var position = $(this).position();
                $('#TUTUTUPAYMENTGATEWAYMODAL').animate({
                    scrollTop: position.top
                }, "slow");
              }else{
                var $parent = $(this).parents('.payment-channel-type');
                var $channel_element = $parent.find('.channel');
                
                $(this).removeClass('active');
                $channel_element.removeClass('active');
  
                var position = $(this).position();
                $('#TUTUTUPAYMENTGATEWAYMODAL').animate({
                    scrollTop: position.top
                }, "slow");
              }

          });

      }

      $.fn.SelectPayment = function(){

          $(this).on('click', function(){
              var $this = $(this);
              var $pay_url = $this.data('pay_url');
              var $payment_name = $this.data('payment_name');
              var $amount = $this.data('amount');

              //Implement Method Payment
              $('.pay_url').val($pay_url);
              $('.winpay-channel-selected').html($payment_name);
              $('.winpay-total-amount-pay').html($amount);

              //Hidden Parent
              $('.payment-summary-frame').slideUp();
              $('.konfirmasi_payment').slideDown();

              $('.back-step').show();
              
          });
          
      }

      $.fn.BackOneStep = function(){

          $(this).on('click', function(){
              var $this = $(this);

              $('.payment-summary-frame').slideDown();
              $('.konfirmasi_payment').slideUp();

              $('.back-step').hide();
              
          });
          
      }


      $.fn.LetsPayNow = function(){

          $(this).on('click', function(){
              var $this = $(this);
              var $pay_url = $this.data('pay_url');
              var $parent = $this.parents('.payment-summary-frame');

              //Disable Back Button
              $('.back-step').hide();
              $('.close-step').hide();

              //Flush Form
              FlushForm();

              //Submit Payment Send To WinPay
              $('form[name="tututu-payment-gateway"]').submit();
              
          });
          
      }

      $.PayNow = function(obj){

          var $paramaters = $.extend({
              order_number : '',
              user_id : '',
              amount : 0,
              item : '',
              OnWait : function(){},
              OnSuccess : function(){},
              OnError : function(){}
          }, obj);

          //Open Popup Payment
          $('#TUTUTUPAYMENTGATEWAYMODAL').modal({backdrop: 'static', keyboard: false});

          //Process Payment Request
          $.ajax({
              url : BASE_URL + 'payment-gateway/index.php',
              method : 'POST',
              dataType : 'JSON',
              data : {
                  order_number : $paramaters.order_number,
                  user_id : $paramaters.user_id,
                  amount : $paramaters.amount,
                  item : $paramaters.item
              },
              beforeSend : function(e){

                  $('.target-html-select-this-tututu-payment-gateway').html(`<div class="tututu-payment-gateway-make-secure-payment">
                  <div class="area-loading">
                    <img src="`+loader_gif+`" alt="Loading"/>
                  </div>
                  <h4>Membuat Koneksi Aman . . .</h4>
              </div>`);

                  var callbacks = $.Callbacks();
                  callbacks.add($paramaters.OnWait);
                  callbacks.fire(e);
              },
              success : function(e){

                  $('.target-html-select-this-tututu-payment-gateway').html(e.html);

                  $('.channel').SelectPayment();
                  $('.wainpay_pay_now').LetsPayNow();
                  $('.winpay_back').BackOneStep();
          
                  //Open Select Payment Method
                  $('.payment-channel-type .page-header').OpenPaymentType();

                  var callbacks = $.Callbacks();
                  callbacks.add($paramaters.OnSuccess);
                  callbacks.fire(e);
              },
              error : function(e){
                  var callbacks = $.Callbacks();
                  callbacks.add($paramaters.OnError);
                  callbacks.fire(e);
              }
          })
      }

  })(jQuery);

  function FlushForm(){
      $('form[name="tututu-payment-gateway"]').on('submit', function(e){

          e.preventDefault();

          var $this = $(this);
          var $url_ajax = $this.attr('action');

          var formData = new FormData(this);

          //Send Data Now
          $.ajax({
              url : $url_ajax,
              dataType : 'JSON',
              method : 'POST',
              data : formData,
              cache: false,
              contentType: false,
              processData: false,
              beforeSend : function(e){
                  $('.target-html-select-this-tututu-payment-gateway').html(`<div class="tututu-payment-gateway-make-secure-payment">
                  <div class="area-loading">
                    <img src="`+loader_gif+`" alt="Loading"/>
                  </div>
              </div>`);
              },
              success : function(e){
                  if(e.link != ''){
                      window.location = e.link;
                  }else{
                      $('.target-html-select-this-tututu-payment-gateway').html(e.html);
                      $('.modal-footer').html(e.footer);
                  }
                  
              },
              error : function(e){
                  // console.log('Failed Send Payment');
              }
          });

      });
  }


  $(document).ready(function(){
      $('.back-step').BackOneStep();
      $('.close-step').on('click', function(){
        return window.location.reload();
      });

      $('.show_payment_winpay').on('click', function(){

        $('#TUTUTUPAYMENTGATEWAYMODAL').modal('show');

        var $order_number = $(this).data('order_number');
        $.PayNow({
            'order_number' : $order_number,
            OnWait : function(e){
                // console.log('Wait');
            },
            OnSuccess : function(e){
                // console.log('Success');
            },
            OnError : function(e){
                // console.log(e.message);
            }
        });
      });
  });
  //End Payment

  var html = '';
  var $html_inject = '';
  $.each(items.product_list, function( index, value ) {

    var imagesProd = value.image;

    html += `<div class="child-list-product-buy">
                <div class="thumbnail">
                  <img src="`+imagesProd+`" alt="`+value.title+`"/>
                </div>
                <div class="title-and-price">
                    <h3>`+value.title+` <span>x`+value.qty+`</span></h3>
                    <h2 style="text-decoration:line-through;font-size:15px;color:#ab0000">`+value.normal_price_without_qty+`</h2>
                    <h4>`+value.special_price_without_qty+`</h4>
                </div>
              </div>`;   
              
              
    $html_inject += `
          <div class="child-summary-cont-form">
            <div class="left">
              <h3>` + value.title +` (<span class="qtySummary">x`+ value.qty +`</span>)</h3>
            </div>
            <div class="right">
              <h4>`+value.normal_price+`</h4>
              <strong>`+value.special_price+`</strong>
            </div>
          </div>
          `;
  });
  $('.list-product-buy').html(html);
  $(".parent-summary-cont-form").html($html_inject);

  return (
      <div>
        <div className="area-buy">
            <div className="buy-left-area">
              <div className="cont-area">
                <div className="cont-header" style={{'border-bottom' : '1px solid #f1f1f1'}}>
                  <h2>No. Invoice #{items.nomor_order}</h2>

                  <div className="right-secure-img">
                    <img src={seal_secure_img} alt="Transaksi Aman"/>
                    <img src={seal_satisfaction_img} alt="Jaminan Kepuasan"/>
                  </div>
                </div>
                {!(paymentReceived) ? 
                <div className="cont-form">

                <div className="header-finish">
                  <img src={payment_picture} alt={'Finish Order'} />
                </div>
               
                <ReactPlaceholder className="loading-waiting-payment" type='text' rows={6} ready={false} style={{'padding-left' : '20px', 'padding-right' : '20px'}}> </ReactPlaceholder>

                <div className="waiting-payment" style={{'display' : 'none'}}>
                  {(() => {
                    if (items.payment_method === '3') {
                    return (
                      <div>
                        <div className="FrameThanks" style={{'padding-top': '0px'}}>
                          <h3 className="thanks_caption">Tinggal satu langkah lagi...</h3>
                          <div>
                            <button className="btn btn-primary show_payment" style={{'marginBottom' : '20px'}} onClick={ShowMidtransPopupPay.bind(this)} data-mid_token={items.mid_token}>
                            <FontAwesomeIcon icon={faArrowRight} style={{'width' : '15px', 'height' : '15px', 'marginLeft' : '0px', 'marginRight' : '10px'}}/> Lanjutkan Pembayaran
                            </button>
                          </div>

                          <table className="table table-hover table-invoice">
                              <tr>
                                <td>Metode Pembayaran</td>
                                <td>:</td>
                                <td style={{'font-weight' : 'bold'}}>GOPAY</td>
                              </tr>
                              <tr>
                                <td>No Invoice</td>
                                <td>:</td>
                                <td style={{'font-weight' : 'bold'}}>#{items.nomor_order}</td>
                              </tr>
                              <tr>
                                <td>Jumlah Tagihan</td>
                                <td>:</td>
                                <td style={{'font-weight' : 'bold'}}>{items.grand_total}</td>
                              </tr>
                              <tr>
                                <td>Waktu Pembelian</td>
                                <td>:</td>
                                <td style={{'font-weight' : 'bold'}}>{items.waktu_order}</td>
                              </tr>
                          </table>

                          <div className="caption_3">
                            <p>Biasanya, <span style={{'color' : 'red'}}>tidak sampai 1 menit setelah Anda melakukan pembayaran,</span> <br/>Anda akan mendapatkan email dari kami yang berisi produk yang Anda beli.</p>
                            <p><strong>Silahkan cek INBOX/SPAM di email Anda</strong>, jika dalam 1 jam Anda masih belum menerima <br/>email dari kami, segera <a href="https://wa.rajalisensi.com/" target="_blank">Hubungi Tim Kami via WhatsApp</a>
                            .</p>
                            <p><img src={secure_checkout} alt="Transaksi Anda 100% Aman" className="secure_checkout_img" style={{'margin-top' : '30px', 'width' : '320px'}} /></p>
                          </div>
                        </div>

                        <div>{((typeof items.mid_token !== 'undefined') ? window.snap.pay(items.mid_token) : '')}</div>
                      </div>
                    )
                    } else if((items.payment_method === '4') && (items.kode_unik2 !== 'Rp0,-')) {
                      return (
                      
                        <div className="FrameThanks" style={{'padding-top': '0px'}}>
                          <h3 className="thanks_caption">Tinggal satu langkah lagi...</h3>
                          <p className="caption_1">Untuk menyelesaikan proses pembelian, silahkan transfer sejumlah:</p>
                          <b className="price_thanks"><span className="price_front">{items.harga_order}</span><span className="price_last" style={{'margin-left' : '5px'}}>{items.kode_unik}</span></b>
                          <small className="price_important_info"><b>PENTING!</b> Harap transfer sampai ke 3 digit terakhir</small>
                          <p className="caption_2">Ke rekening berikut ini:</p>
                          <div className="payment_acount_detil">
                            <div className="child-bank" style={{'border-right' : '2px dashed #bdbdbd'}}>
                              <img src={bank_bca} alt="Bank BCA" style={{'margin-top' : '30px'}}/>
                              <h3>Bank BCA</h3>
                              <h4>No. Rek: <b className="account_number">5315119925</b></h4>
                              <h4>Atas Nama: <b>Bagas Pramudita</b></h4>
                            </div>
                            <div className="child-bank" style={{'border-right' : '2px dashed #bdbdbd'}}>
                              <img src={bank_mandiri} alt="Bank Mandiri" style={{'margin-top' : '-10px'}}/>
                              <h3 style={{'margin-top' : '-6px'}}>Bank Mandiri</h3>
                              <h4>No. Rek: <b className="account_number">9000010527001</b></h4>
                              <h4>Atas Nama: <b>Bagas Pramudita</b></h4>
                            </div>
                            <div className="child-bank">
                              <img src="https://semutganteng.fra1.digitaloceanspaces.com/StaticAssets/logo_bni_large.png" alt="Bank Mandiri" style={{'margin-top' : '-10px'}}/>
                              <h3 style={{'margin-top' : '-6px'}}>Bank BNI</h3>
                              <h4>No. Rek: <b className="account_number">1275197552</b></h4>
                              <h4>Atas Nama: <b>Bagas Pramudita</b></h4>
                            </div>
                    
                          </div>
                          <div className="caption_3">
                            <p>Jika sudah melakukan pembayaran, silakan hubungi CS kami untuk proses konfirmasi pembayaran yang lebih cepat.</p>
                            <a href={items.link_konfirmasi} target="_blank" className='btn-konfirmasi-bayar'>Konfirmasi Pembayaran</a>
                            <p style={{display: 'none'}}>Perlu diperhatikan, <span style={{'color' : 'red'}}>Jika Anda TRANSFER sesuai dengan nominal yang ditagihkan</span>, maka kurang dari 2 menit setelah Anda transfer, produk akan dikirim otomatis ke email Anda.</p>
                            <p>Jika jumlah transfer Anda tidak sesuai dengan yang ditagihkan, <a href="https://wa.rajalisensi.com/" target="_blank">Hubungi Tim Kami via WhatsApp</a> atau silahkan <a href={items.link_konfirmasi} target="_blank">Konfirmasi Pembayaran</a>.</p>
                            <p><img src={secure_checkout} alt="Transaksi Anda 100% Aman" className="secure_checkout_img" style={{'margin-top' : '30px', 'width' : '320px'}} /></p>
                          </div>
                        </div>
                      
                      )
                    } else if((items.payment_method === '4') && (items.kode_unik2 === 'Rp0,-')) {
                      return (
                      
                        <div>
                          <div className="FrameThanks" style={{'padding-top': '0px'}}>
                            <h3 className="thanks_caption">Tinggal satu langkah lagi...</h3>
                            <div>
                              <a className="btn btn-primary" style={{'marginBottom' : '20px'}} target="_blank" href={items.link_selesaikan_pembayaran}>
                                <FontAwesomeIcon icon={faArrowRight} style={{'width' : '15px', 'height' : '15px', 'marginLeft' : '0px', 'marginRight' : '10px'}}/> {items.text_button_winpay}
                              </a>
                            </div>
                            
                            <table className="table table-hover table-invoice">
                                <tr>
                                  <td>Metode Pembayaran</td>
                                  <td>:</td>
                                  <td style={{'font-weight' : 'bold'}}><span dangerouslySetInnerHTML={{ __html: items.metode_bayar }} /></td>
                                </tr>
                                <tr>
                                  <td>Status Pembayaran</td>
                                  <td>:</td>
                                  <td style={{'font-weight' : 'bold'}}>{items.status_pembayaran}</td>
                                </tr>
                                <tr>
                                  <td>No Invoice</td>
                                  <td>:</td>
                                  <td style={{'font-weight' : 'bold'}}>#{items.nomor_order}</td>
                                </tr>
                                <tr>
                                  <td>Jumlah Tagihan</td>
                                  <td>:</td>
                                  <td style={{'font-weight' : 'bold'}}>{items.grand_total}</td>
                                </tr>
                                <tr>
                                  <td>Waktu Pembelian</td>
                                  <td>:</td>
                                  <td style={{'font-weight' : 'bold'}}>{items.waktu_order}</td>
                                </tr>
                            </table>

                            <div className="caption_3">
                              <p>Biasanya, <span style={{'color' : 'red'}}>tidak sampai 1 menit setelah Anda melakukan pembayaran,</span> <br/>Anda akan mendapatkan email dari kami yang berisi produk yang Anda beli.</p>
                              <p><strong>Silahkan cek INBOX/SPAM di email Anda</strong>, jika dalam 1 jam Anda masih belum menerima <br/>email dari kami, segera <a href="https://wa.rajalisensi.com/" target="_blank">Hubungi Tim Kami via WhatsApp</a>  atau silahkan <a href={items.link_konfirmasi} target="_blank">Konfirmasi Pembayaran</a>.
                              .</p>
                              <p><img src={secure_checkout} alt="Transaksi Anda 100% Aman" className="secure_checkout_img" style={{'margin-top' : '30px', 'width' : '320px'}} /></p>
                            </div>
                          </div>
                      </div>
                      
                      )
                    }
                  })()}
                </div>
       
              </div>
                : <></>}
                
              </div>

              {!(paymentReceived) ? 
                <div className="cont-area" style={{'padding-left' : '0px', 'padding-right' : '0px'}}>
                <div className="cont-header" style={{'padding-left' : '20px', 'padding-right' : '20px'}}>
                  <h2>Pembelian Anda</h2>
                </div>
                <div className="cont-form">
                  <div className="list-product-buy">
                    {/* Digenerate Jin */}
                    <ReactPlaceholder type='text' rows={4} ready={false} style={{'padding-left' : '20px', 'padding-right' : '20px'}}>
                    </ReactPlaceholder>
                  </div>
                </div>
              </div>
              : 
              
              <div className="cont-form-flex">

                <div className="header-finish">
                  <img src='https://semutganteng.fra1.digitaloceanspaces.com/StaticAssets/logo-checklist.png' alt={'Payment Received'} />
                </div>
                <h3 className='greetings-header'>Pembayaran Anda Berhasil Diterima</h3>
                <p className='greetings-text'>Kami juga telah mengirimkan email yang berisi detail pesanan anda.</p>

                <Link to={'/'} className='other-products-buttons'>
                  Lihat Produk Lainya
                </Link>
              </div>
              }
              
            </div>
            <div className="buy-right-area">
              <div className="cont-area">
                <div className="cont-header">
                  <h2>Rincian Pesanan</h2>
                  <hr/>
                </div>
                <div className="frame-summary-right">
                  <div className="cont-form" style={{'marginTop': '0px'}}>
                    <div className="parent-summary-cont-form">
                      {/* <!-- Digenerate Jin --> */}
                      <ReactPlaceholder type='text' rows={2} ready={false}>
                      </ReactPlaceholder>
                    </div>
                  </div>
                  <div className="cont-form">
                    <div className="summary-area">
                      <div className="child-summary" style={((items.biaya_layanan !== 'Rp0,-') ? {'display' : 'block'} : {'display' : 'none'})}>
                            <strong className="left">
                              Biaya Layanan
                            </strong>
                            <strong className="right">
                              {items.biaya_layanan}
                            </strong>
                        </div>
                        <div className="child-summary">
                            <strong className="left">
                              Sub Total
                            </strong>
                            <strong className="right sub-total-payment">
                              {items.sub_total}
                            </strong>
                        </div>
                        <div className="child-summary diskon" style={((items.diskon_total !== 'Rp0,-') ? {'display' : 'block'} : {'display' : 'none'})}>
                            <strong className="left">
                              Diskon
                            </strong>
                            <strong className="right">
                              {items.diskon_total}
                            </strong>
                        </div>
                        {(() => {
                          if (items.kode_unik2 !== 'Rp0,-') {
                          return (
                            <div className="child-summary">
                                <strong className="left">
                                  Kode Unik
                                </strong>
                                <strong className="right">
                                  {items.kode_unik2}
                                </strong>
                            </div>
                          );
                        }
                        })()}
                    </div>
                    <div className="grandtotal-area">
                      <strong className="left">
                        Grand Total
                      </strong>
                      <strong className="right total-payment">
                        {items.grand_total}
                      </strong>
                    </div>
                  </div>
                </div>
                <div className="box-white box-bantuan-shopping-cart">
                    <div className="help-cart">
                      <div className="left-info">
                        <img src={support_png} alt="Bantuan - RajaLisensi.com"/>
                      </div>
                      <div className="right-info">
                        <h1>Bantuan</h1>
                        <p>Hubungi <a href="https://wa.rajalisensi.com" target="_blank">WhatsApp</a> atau
                        email ke <a href="mailto:info@mail.rajalisensi.com">info@mail.rajalisensi.com</a> jika terjadi
                        kendala dalam proses belanja.</p>
                      </div>
                    </div>
                </div>
              </div>
              <div className="cont-area" style={{"background" : "#3c7d5b", "color" : "#fff", "min-height" : "auto", "display" : "flex", "justifyContent" : "flex-start", "gap" : "20px"}}>
                  <div className="tg-img">
                    <img src="https://rajalisensi.com/wp-content/uploads/2020/11/waloga-min.png" border="0"/>
                  </div>
                  <div className="tg-hd">
                      <div className="tg-head1">Mau Software Gratis?</div>
                      <div className="tg-desc">Dapatkan update software gratis dan penawaran spesial hanya melalui Grup WhatsApp kami.</div>
                      <div className="tg-btn">
                        <a href="https://chat.whatsapp.com/FnbK68DwbM2BYlP25JM3o9" target="_blank">
                          <button className="btn-tg-official">Join Sekarang</button>
                        </a>
                      </div>
                  </div>
              </div>
            </div>
        </div>

        <div className="modal fade" id="TUTUTUPAYMENTGATEWAYMODAL" role="dialog" aria-labelledby="TUTUTUPAYMENTGATEWAYMODAL" aria-hidden="true">
          <div className="modal-dialog" role="document" style={{'max-width' : '410px'}}>
              <div className="modal-content modal-content-tututu-payment-gateway">
                    <div className="modal-header">
                        <a href="javascript:;" className="back-step" style={{'display' : 'none'}}>
                          <FontAwesomeIcon icon={faArrowLeft}/>
                        </a>
                        <h5 className="modal-title" id="TUTUTUPAYMENTGATEWAYMODALLabel">
                            <img src={logo_rl_png} alt="BELIYO.COM"/>
                        </h5>
                        <a href="javascript:;" className="close-step close">
                            <FontAwesomeIcon icon={faTimesCircle}/>
                        </a>
                    </div>
                    <div className="modal-body target-html-select-this-tututu-payment-gateway">
                        {/* Di generate JIN */}
                    </div>
                    <div className="modal-footer">
                        <div className="tututu-payment-gateway-footer-copyright">
                            <p>Kami bekerja sama dengan WinPay Payment Gateway dalam proses pembayaran berikut ini, semua data transaksi yang anda masukan telah terenkripsi dengan aman oleh WinPay</p>
                        </div>
                    </div>
                </div>
          </div>
        </div>
        
      </div>
  );
  

}

function ListDownsellinProducts(parameters){
   
  let urlGetListProduct = baseUrl + 'get_product_downselling.php';
  const secretHash = hmacSHA256(urlGetListProduct, buildKey).toString();
  const apiUrl =  urlGetListProduct + '?apikey=' + secretHash + '&order_id=' + parameters.order_id;

  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    async function fetchData() {
      var data = await fetch(apiUrl).then(res => {
        //console.log(res);
        return res.json();
      });
      //console.log(data);
      setItems(data.products);
      
      
      //console.log(data);
    }
    fetchData();
  }, [apiUrl]);

  return (
    <div className="col-lg-12 row">
      {items.map(item => (
      <div className="col-lg-4 list-product">
        <img src={(() => {
        switch (item.image_name) {
          case "visio-windows-office":   return bundling_visio_office_windows;
          case "visio-windows":   return bundling_visio_windows;
          case "visio-office":   return bundling_visio_office;
          case "avg":   return avg;
          case "windows-10-pro":   return windows_box;
          case "office-365": return office_box;
          case "office-365-custom": return office_box;
          case "internet-download-manager":  return idm_box;
          case "bitdefender-internet-security":  return bd_box;
          case "project-2019":  return project_box;
          case "visio-2019":  return visio_box;
          case "bundling-windows-office365":  return bundling_office_windows;
          case "idm-office":  return bundling_idm_office;
          case "idm-windows":  return bundling_idm_windows;
          case "bundling-visio-project2019":  return bundling_visio_project;
          case "hma-vpn":  return hma_box;
          default:      return "";
        }
      })()} alt={item.produk}/>
        <h2>{item.produk}</h2>
        {item.has_added ? <Link className="remove" onClick={RemoveToChartDownselling.bind(this)} data-token={item.token} data-downselling_id={item.downselling_id}>Hapus Dari Keranjang</Link>: <Link onClick={AddToChartDownSelling.bind(this)} data-token={item.token} data-downselling_id={item.downselling_id}>Beli Sekarang <small>(Hemat : {item.diskon})</small></Link> }

        <div className="elementor-widget-container">
          <div style={{'textAlign' : 'center', 'color' : 'red', 'font-size' : '14px'}}> 
            <strike>{item.harga_produk}</strike>
          </div>
          <div style={{'textAlign' : 'center', 'color' : '#242424', 'font-size' : '20px','fontWeight' : 'bold', 'marginTop' : '5px'}}> 
            {item.downselling_price}
          </div>
        </div>
      </div>
      ))}
    </div>
  );
}

function ListUpsellinProducts(parameters){
   
  let urlGetListProduct = baseUrl + 'get_product_upselling.php';
  const secretHash = hmacSHA256(urlGetListProduct, buildKey).toString();
  const apiUrl =  urlGetListProduct + '?apikey=' + secretHash + '&order_id=' + parameters.order_id;

  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    async function fetchData() {
      var data = await fetch(apiUrl).then(res => {
        //console.log(res);
        return res.json();
      });
      //console.log(data);
      setItems(data.products);
      
      
      //console.log(data);
    }
    fetchData();
  }, [apiUrl]);

  return (
    <div className="col-lg-12 row">
      {items.map(item => (
      <div className="col-lg-4 list-product">
        <img src={(() => {
        switch (item.image_name) {
          case "visio-windows-office":   return bundling_visio_office_windows;
          case "visio-windows":   return bundling_visio_windows;
          case "visio-office":   return bundling_visio_office;
          case "avg":   return avg;
          case "windows-10-pro":   return windows_box;
          case "office-365": return office_box;
          case "office-365-custom": return office_box;
          case "internet-download-manager":  return idm_box;
          case "bitdefender-internet-security":  return bd_box;
          case "project-2019":  return project_box;
          case "visio-2019":  return visio_box;
          case "bundling-windows-office365":  return bundling_office_windows;
          case "idm-office":  return bundling_idm_office;
          case "idm-windows":  return bundling_idm_windows;
          case "bundling-visio-project2019":  return bundling_visio_project;
          case "hma-vpn":  return hma_box;
          default:      
          return no_image_found;
        }
      })()} alt={item.produk}/>
        <h2>{item.produk}</h2>
        {item.has_added ? <Link className="remove" onClick={RemoveToChart.bind(this)} data-token={item.token} data-upselling_id={item.upselling_id}>Hapus Dari Keranjang</Link>: <Link onClick={AddToChart.bind(this)} data-token={item.token} data-upselling_id={item.upselling_id}>Beli Sekarang <small>(Hemat : {item.diskon})</small></Link> }
        
        <div className="elementor-widget-container">
          <div style={{'textAlign' : 'center', 'color' : 'red', 'font-size' : '14px'}}> 
            <strike>{item.harga_produk}</strike>
          </div>
          <div style={{'textAlign' : 'center', 'color' : '#242424', 'font-size' : '20px','fontWeight' : 'bold', 'marginTop' : '5px'}}> 
            {item.upselling_price}
          </div>
        </div>
      </div>
      ))}
      ;
    </div>
  );
}

function NotFoundPage(){

  // eslint-disable-next-line no-restricted-globals
  var $currentUrl = $(location).attr('href');
  var res = $currentUrl.split("/produk/");

  //console.log('resCuk', res);

  if(res.length > 1){
    if(res[1].length > 0){
      return null;
    }else{
      return (
        <div className="not-found-page">
          <div className="img-not-found">
            <img src={notfound_img} alt="Not Found"/>
          </div>
          <div className="page-header notfon">
            <h2>404: Halaman Tidak Ditemukan</h2>
            <h3>Mohon maaf, Halaman yang Anda tuju tidak tersedia.</h3>
            <center>
              <Link to={'/'}>
                <button type="button" className="btn btn-info">
                  Kembali ke Beranda
                  </button>
                </Link>
            </center>
          </div>
        </div>
      );
    }
  }else{
    return (
      <div className="not-found-page">
        <div className="img-not-found">
          <img src={notfound_img} alt="Not Found"/>
        </div>
        <div className="page-header notfon">
          <h2>404: Halaman Tidak Ditemukan</h2>
          <h3>Mohon maaf, Halaman yang Anda tuju tidak tersedia.</h3>
          <center>
            <Link to={'/'}>
              <button type="button" className="btn btn-info">
                Kembali ke Beranda
                </button>
              </Link>
          </center>
        </div>
      </div>
    );
  }

}

function ListProducts() {

  ReactPixel.pageView();
 
  let urlGetListProduct = baseUrl + 'get_list_product.php';
  const secretHash = hmacSHA256(urlGetListProduct, buildKey).toString();
  const apiUrl =  urlGetListProduct + '?apikey=' + secretHash;
  
  // Moved.gO('/x');
  // console.log('secretHash', secretHash);
  // console.log('urlGetListProduct', urlGetListProduct);
  // console.log('apiUrl', apiUrl);
  
  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    ReactPixel.track('PageView', {});
  },[])

  React.useEffect(() => {
    async function fetchData() {
      var data = await fetch(apiUrl).then(res => {
        //console.log(res);
        return res.json();
      });
      //console.log(data);
      setItems(data.products);
      //console.log(data);
    }
    fetchData();
  }, [apiUrl]);

  return (
    <div className="col-lg-12 row" style={{'margin':'0px','padding' : '0px'}}>
      {items.map(item => (
      <div className="col-lg-4 list-product">
        <img src={item.image} alt={item.title}/>
        <h2>{item.title}</h2>
        <Link to={item.link}>Beli Sekarang</Link>
        <div className="elementor-widget-container">
          <div style={{'textAlign' : 'center', 'color' : 'red', 'font-size' : '14px'}}> 
            <strike>{item.hargaasli}</strike>
          </div>
          <div style={{'textAlign' : 'center', 'color' : '#242424', 'font-size' : '20px','fontWeight' : 'bold', 'marginTop' : '5px'}}> 
            {item.hargajual}
          </div>
        </div>
      </div>
      ))}
    </div>
  );
}

function applyCoupon(element){
  couponValue = element.val();
  
  if(couponValue.length > 3){

    var offers_id = element.data('offers_id');

    const apiUrl =  baseUrl + 'apply_coupon.php';
    const data = new FormData();

    data.set('offers_id', offers_id);
    data.set('couponValue', couponValue);

    if(typeof offers_id != 'undefined'){
      if(offers_id != null){
        async function fetchData(dataSend) {

          var data = await fetch(apiUrl, {
            method: 'POST',
            // eslint-disable-next-line no-use-before-define
            body: dataSend,
          }).then(res => {
            //console.log(res.clone().json());
            return res.clone().json();
          });
      
          couponId = data.coupon;
          couponAmount = data.amount;
          couponType = data.type;

          reCountingOrders();

          if(data.status){
            $('.diskon').show();
            $('.diskon').find('.right').html(formatRupiah(parseInt(data.amount).toString(), 'Rp'));
            
            // toast.success(data.message, {
            //     position: toast.POSITION.BOTTOM_RIGHT
            // });

            $('.btnApplyCoupon').removeClass('btn-success').addClass('btn-secondary');
            $('.btnApplyCoupon').html('Applied');

            $('.kupon-failed').hide();
            $('.kupon-success').show().find('.result_kupon').html('<strong>'+data.message+'</strong>');
          }else{

            $('.btnApplyCoupon').addClass('btn-success').removeClass('btn-secondary');
            $('.btnApplyCoupon').html('Apply');
            $('.diskon').hide();

            $('.kupon-failed').show();
            $('.kupon-success').hide().find('.result_kupon').html('');

            // toast.error(data.message, {
            //   position: toast.POSITION.BOTTOM_RIGHT
            // });
          }

        }
      
        fetchData(data);
      }
    }
  }else{
    toast.error('Silahkan inputkan kode kupon terlebih dahulu', {
      position: toast.POSITION.BOTTOM_RIGHT
    });
  }

}


function handleSubmitKonfirmasi(event){
  event.preventDefault();

  const apiUrl =  baseUrl + 'confirm_payment.php';

  var $target = $(event.target);
  var dataNew = $target.serializeArray();

  //console.log('dataNew', dataNew);

  const data = new FormData(event.target);
  const coupon = couponId;

  data.set('coupon', coupon);
  $.each(dataNew, function( index, value ) {
    data.set(value.name, value.value);
  });

  var input = document.querySelector('input[type="file"]');
  data.append('file', input.files[0]);

  $('.konfirmasi_pembayaran').attr('disabled','disabled');
  $('.konfirmasi_pembayaran').val('Silahkan Tunggu...');

  async function fetchData(dataSend) {

    var data = await fetch(apiUrl, {
      method: 'POST',
      // eslint-disable-next-line no-use-before-define
      body: dataSend,
    }).then(res => {
      //console.log(res.clone().json());
      return res.clone().json();
    });

    // console.log('data.link', data.link);
    if(data.status){
      window.location.replace(data.link);

      $('.konfirmasi_pembayaran').val('Selesai');
    }else{
      toast.error(data.message, {
        position: toast.POSITION.BOTTOM_RIGHT
      });

      $('.konfirmasi_pembayaran').removeAttr('disabled');
      $('.konfirmasi_pembayaran').val('Kirim');

    }
    
  }

  fetchData(data);
}

function handleSubmit(event) {
  event.preventDefault();
  // Moved.gO('/x');
  
  const apiUrl =  baseUrl + 'place_order.php';

  var $target = $(event.target);
  var dataNew = $target.serializeArray();

  //console.log('dataNew', dataNew);

  const data = new FormData(event.target);
  const coupon = couponId;

  data.set('coupon', coupon);
  $.each(dataNew, function( index, value ) {
    data.set(value.name, value.value);
  });

  $('.btn_process_checkout').attr('disabled','disabled');
  $('.btn_process_checkout').find('.onLoading').show();
  $('.btn_process_checkout').find('.onNormal').hide();
  $('.btn_process_checkout').find('.onClickSubmitText').html('Silahkan Tunggu...');

  async function fetchData(dataSend) {

    var data = await fetch(apiUrl, {
      method: 'POST',
      // eslint-disable-next-line no-use-before-define
      body: dataSend,
    }).then(res => {
      //console.log(res.clone().json());
      return res.clone().json();
    });

    // console.log('data.link', data.link);
    if(data.status){
      window.location.replace(data.link);

      $('.btn_process_checkout').find('.onClickSubmitText').html('Mengarahkan...');
    }else{
      toast.error(data.message, {
        position: toast.POSITION.BOTTOM_RIGHT
      });

      $('.btn_process_checkout').removeAttr('disabled');
      $('.btn_process_checkout').find('.onLoading').hide();
      $('.btn_process_checkout').find('.onNormal').show();
      $('.btn_process_checkout').find('.onClickSubmitText').html('Lanjutkan Pembayaran');

    }
    
  }

  fetchData(data);
  
}

function fieldOnlyEmail(event) {
 
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);

  //console.log('keyValue',keyValue);

  if ((keyValue.match(/[^@a-zA-Z0-9._blank]/g)) && (keyValue !== '-'))
     event.preventDefault();
}

function fieldOnlyStringAndNumber(event) {
 
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);

   if (keyValue.match(/[^a-z0-9]/g))
     event.preventDefault();
}

function fieldOnlyString(event) {
 
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);

   if (keyValue.match(/[^a-zA-Z ]/g))
     event.preventDefault();
}

function fieldOnlyNumber(event) {
 
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);

   if (!keyValue.match(/^-{0,1}\d+$/))
     event.preventDefault();
}

function formatRupiah(angka, prefix){
  var number_string = angka.replace(/[^,\d]/g, '').toString(),
  split   		= number_string.split(','),
  sisa     		= split[0].length % 3,
  rupiah     		= split[0].substr(0, sisa),
  ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);

  var separator = '';

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if(ribuan){
    separator = sisa ? '.' : '';
    rupiah += separator + ribuan.join('.');
  }

  rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
  return prefix === undefined ? rupiah : (rupiah ? 'Rp' + rupiah + ',-' : '');
}


function setCouponCode(event){
  //console.log('value', event.target.value);
  couponValue = event.target.value;
}

function reCountingOrders(extraprice = 0){

  var harga_total_From_tmp = 0;
  $(".sub_total_price").each(function(index){
    harga_total_From_tmp += parseInt($(this).val());
  });

  if(typeof extraprice !== 'undefined'){
    if(parseInt(extraprice) > 0){
      harga_total_From_tmp += extraprice;
    }
  }
  //console.log('harga_total_From_tmp', harga_total_From_tmp);

  if(parseInt(couponAmount) > 0){
    if(parseInt(couponType) === 1){
      var subTotalHargaJual = (parseInt(harga_total_From_tmp) - parseInt(couponAmount));
      // var totalHargaJual = subTotalHargaJual + TotalKodeUnik;
      var totalHargaJual = subTotalHargaJual;

      $('.sub-total-payment').html(formatRupiah(parseInt(harga_total_From_tmp).toString(), 'Rp'));
      $('.total-payment').html(formatRupiah(totalHargaJual.toString(), 'Rp'));
      $('.total-payment-no-format').html(totalHargaJual.toString());
    }else{
      var subTotalHargaJual2 = (parseInt(harga_total_From_tmp) - (parseInt(harga_total_From_tmp) * parseFloat(couponAmount)));
      // var totalHargaJual2 = subTotalHargaJual2 + TotalKodeUnik;
      var totalHargaJual2 = subTotalHargaJual2;

      $('.sub-total-payment').html(formatRupiah(parseInt(harga_total_From_tmp).toString(), 'Rp'));
      $('.total-payment').html(formatRupiah(totalHargaJual2.toString(), 'Rp'));
      $('.total-payment-no-format').html(totalHargaJual2.toString());
    }
  }else{
    var subTotalHargaJual3 = (parseInt(harga_total_From_tmp));
    // var totalHargaJual3 = subTotalHargaJual3 + TotalKodeUnik;
    var totalHargaJual3 = subTotalHargaJual3;

    $('.sub-total-payment').html(formatRupiah(parseInt(harga_total_From_tmp).toString(), 'Rp'));
    $('.total-payment').html(formatRupiah(totalHargaJual3.toString(), 'Rp'));
    $('.total-payment-no-format').html(totalHargaJual3.toString());
  }

  draw_summary_count();

}

function updateQtyButton(){
  // console.log($(event));

  $('.input_qty').bind('keyup paste', function(){
      this.value = this.value.replace(/[^0-9]/g, '');

      var $parent = $(this).parents('.child-list-product-buy');

      // console.log('event',event);
      console.log('$parent',$parent);
  
      var $alias = $parent.attr('alias');
      var keyValue = $parent.find('.input_qty').val();
      $parent.find('.qtySummary_'+$alias).html('x'+keyValue);
  
      var hargajual = $parent.find('.btn-decrease').data('hargajual');
      var kodeunik = $('.kodeunik').val();
      
      hargaJualTotalWithoutKodeUnik = (parseInt(keyValue) * parseInt(hargajual));
      $parent.find('.sub_total_price').val(hargaJualTotalWithoutKodeUnik);

      TotalKodeUnik = parseInt(kodeunik);
  
      // $('.child-list-product-buy');
      
      reCountingOrders();
  });

    $('.btn-decrease').on('click', function(){
      var $parent = $(this).parents('.child-list-product-buy');

      // console.log('event',event);
      // console.log('$parent',$parent);
  
      var $alias = $parent.attr('alias');
      var keyValue = $parent.find('.input_qty').val();
      $parent.find('.qtySummary_'+$alias).html('x'+keyValue);
  
      var hargajual = $(this).data('hargajual');
      var kodeunik = $('.kodeunik').val();
      
      hargaJualTotalWithoutKodeUnik = (parseInt(keyValue) * parseInt(hargajual));
      $parent.find('.sub_total_price').val(hargaJualTotalWithoutKodeUnik);

      TotalKodeUnik = parseInt(kodeunik);
  
      // $('.child-list-product-buy');
      
      reCountingOrders();
    });

    $('.btn-increase').on('click', function(){
      var $parent = $(this).parents('.child-list-product-buy');

      console.log('Nambah');

      // console.log('event',event);
      //console.log('$parent',$parent);
  
      var $alias = $parent.attr('alias');
      var keyValue = $parent.find('.input_qty').val();
      $parent.find('.qtySummary_'+$alias).html('x'+keyValue);
  
      var hargajual = $(this).data('hargajual');
      var kodeunik = $('.kodeunik').val();
      
      hargaJualTotalWithoutKodeUnik = (parseInt(keyValue) * parseInt(hargajual));
      $parent.find('.sub_total_price').val(hargaJualTotalWithoutKodeUnik);

      TotalKodeUnik = parseInt(kodeunik);
  
      // $('.child-list-product-buy');
      
      reCountingOrders();
    });
    
}

function updateQty(event) {
 
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);

   if (!keyValue.match(/^-{0,1}\d+$/)){
    event.preventDefault();
   }else{

      var hargajual = event.target.getAttribute('data-hargajual');
      var kodeunik = event.target.getAttribute('data-kodeunik');
      
      hargaJualTotalWithoutKodeUnik = (parseInt(keyValue) * parseInt(hargajual));
      TotalKodeUnik = parseInt(kodeunik);
      
      reCountingOrders();
   }
     
}


function showHideWithEffect1(element){
  // $(element.target).on("click", function(){

    // console.log('Trigger : ', payment_method);
    // paymentMethod = payment_method;    
    
    paymentMethod = 1;

    $(".content-payment-area").slideUp();
    $(".icon-header-payment").addClass("fa-angle-down").removeClass("fa-angle-up");
    
    var $parents = $(element.target).parents('.payment-area');
    if($parents.find(".content-payment-area").is(":hidden")){
        $parents.find(".content-payment-area").slideDown();
        $parents.find(".icon-header-payment").removeClass("fa-angle-down").addClass("fa-angle-up");
    }else{
        $parents.find(".content-payment-area").slideUp();
        $parents.find(".icon-header-payment").addClass("fa-angle-down").removeClass("fa-angle-up");
    }
  // });
}

function showHideWithEffect2(element){
  // $(element.target).on("click", function(){

    // console.log('Trigger : ', payment_method);
    // paymentMethod = payment_method;   

    paymentMethod = 2;    

    $(".content-payment-area").slideUp();
    $(".icon-header-payment").addClass("fa-angle-down").removeClass("fa-angle-up");
    
    var $parents = $(element.target).parents('.payment-area');
    if($parents.find(".content-payment-area").is(":hidden")){
        $parents.find(".content-payment-area").slideDown();
        $parents.find(".icon-header-payment").removeClass("fa-angle-down").addClass("fa-angle-up");
    }else{
        $parents.find(".content-payment-area").slideUp();
        $parents.find(".icon-header-payment").addClass("fa-angle-down").removeClass("fa-angle-up");
    }
  // });
}


function draw_summary_count(){
  var $html_inject = '';

  //console.log('Masuk Sini Draw Summary');

  $(".child-list-product-buy").each(function(){
    var $this = $(this);

    //console.log($this);

    var $input_qty = parseInt($this.find(".input_qty").val());
    var $product_name = $this.attr("product_name");
    var $hargajual = parseInt($this.attr("hargajual"));
    var $hargaasli = parseInt($this.attr("hargaasli"));
    
    var $total_hargajual = $hargajual * $input_qty;
    var $total_hargaasli = $hargaasli * $input_qty;
    
    $html_inject += `
    <div class="child-summary-cont-form">
      <div class="left">
        <h3>` + $product_name +` (<span class="qtySummary">x`+ $input_qty +`</span>)</h3>
      </div>
      <div class="right">
        <h4>`+formatRupiah($total_hargaasli.toString(), 'Rp')+`</h4>
        <strong>`+formatRupiah($total_hargajual.toString(), 'Rp')+`</strong>
      </div>
    </div>
    `;
  });

  $(".parent-summary-cont-form").html($html_inject);
}

function reCall(){
  $('.moveToTrash').on('click', function(e){
    e.preventDefault();

    var $from_element = $(this).attr('from-element');
    // eslint-disable-next-line no-restricted-globals
    var $confirmation = confirm('Yakin ingin hapus produk ini?');

    if($confirmation){
      if($('.child-list-product-buy').length > 1){
        var $parent = $(this).parents('.child-list-product-buy');
        $parent.remove();
  
        if(typeof $from_element !== 'undefined'){
          $('.'+$from_element).show();
        }
    
        toast.success('Berhasil dihapus', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
  
        reCountingOrders();
      }else{
        // toast.error('Orderan tidak boleh kosong', {
        //   position: toast.POSITION.BOTTOM_RIGHT
        // });
  
        var $parent2 = $(this).parents('.child-list-product-buy');
        $parent2.remove();
  
        window.location = 'https://rajalisensi.com';
      }
    }
    
  });
}

function FinishKonfirmasiView(){

  document.title = 'Konfirmasi Pembayaran Berhasil';

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const order_id = params.get('id');

  return (
    <div className="parent-payment-confirmation">
        <div className="app-header">
          <h1 className="title">Konfirmasi Pembayaran</h1>
        </div>
        <div className="app-content">
          <p className="finishconfirm-text">Terima kasih telah melakukan konfirmasi pembayaran. Pesanan anda akan segera kami proses.</p>
          <p className="finishconfirm-text">Ingin diproses lebih cepat?</p>
          <a className='btn-konfirmasi-bayar block-type' href={'https://api.whatsapp.com/send?phone=6281998367210&text=Halo%20Raja%20Lisensi%2C%20Saya%20ingin%20konfirmasi%20pembayaran%20dengan%20nomor%20invoice%20%23' + order_id} target='_blank'>Hubungi CS</a>
        </div>
    </div>
  );
}

function KonfirmasiDetil(parameters){
  // const order_id = parameters.order_id;

  document.title = 'Konfirmasi Pembayaran';

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const order_id = params.get('id');

  
  let urlGetListProduct = baseUrl + 'get_order_info.php';
  const secretHash = hmacSHA256(urlGetListProduct, buildKey).toString();
  const apiUrl =  urlGetListProduct + '?apikey=' + secretHash + '&order_number=' + order_id;

  const [items, setItems] = React.useState({});
  const [startDate, setStartDate] = React.useState(new Date());

  React.useEffect(() => {
    ReactPixel.track('PageView', {});
  },[])

  React.useEffect(() => {
    async function fetchData() {
      var data = await fetch(apiUrl).then(res => {
        // console.log(res.clone().json());
        return res.clone().json();
      });
      
      //console.log(data.status);

      if(data.status){
        $(document).ready(function(){
          $('input').removeAttr('disabled');
          $('.cek_no_pesanan').attr('disabled','disabled');
          $('.cek_no_pesanan').addClass('disabled');
          $('select').removeAttr('disabled');
    
          $('.area-if-exist').show();
        });

        //console.log('Masuk Cok');
      }else{
       $(document).ready(function(){
        $('.no_pesanan').removeAttr('readonly');
        $('.no_pesanan').removeAttr('disabled');

        $('.cek_no_pesanan').removeClass('disabled');
        $('.cek_no_pesanan').removeAttr('disabled');

        if($('.no_pesanan').val().length > 0){
          $('.warn-order-not-found').show();
        }
        
       });

        //console.log('Keluar Cok');
      }

      setItems(data.details);

      //console.log(data.details);
    }
    fetchData();
  }, [apiUrl]);



  // $('input').attr('disabled','disabled');
  // $('select').attr('disabled','disabled');

  $('.cek_no_pesanan').on('click', function(){
    //window.location = '/konfirmasi/?id=' + $('.no_pesanan').val();
    const apiUrlAjax =  urlGetListProduct + '?apikey=' + secretHash + '&order_number=' + $('.no_pesanan').val();
    $.ajax({
      url : apiUrlAjax,
      method : 'GET',
      beforeSend : function(e){
        $('.area-if-exist').hide();
        $('input').attr('disabled','disabled');
        $('select').attr('disabled','disabled');
      },
      success : function(e){

        const resp = JSON.parse(e);

        if(resp.status){

          $('.area-if-exist').show();

          setItems(resp.details);

          $('.warn-order-not-found').hide();
          $('input').removeAttr('disabled');
          $('select').removeAttr('disabled');
        }else{
          $('.warn-order-not-found').show();
          $('.area-if-exist').hide();

          $('.no_pesanan').removeAttr('readonly');
          $('.no_pesanan').removeAttr('disabled');
        }
        
      }
    });

  });

  if(typeof order_id !== 'undefined'){
    if(order_id !== null){
      $(document).ready(function(){
        //$('.no_pesanan').attr('readonly','readonly');
      });
    }else{
      $('.no_pesanan').removeAttr('readonly');
      $('.no_pesanan').removeAttr('disabled');
    }
  }else{
    $('.no_pesanan').removeAttr('readonly');
    $('.no_pesanan').removeAttr('disabled');
  }

  return (
    <div className="parent-payment-confirmation">
        <div className="app-header">
          <h1 className="title">Konfirmasi Pembayaran</h1>
        </div>
        <div className="app-content">
          <form name="confirmation-payment" action="" method="POST" onSubmit={handleSubmitKonfirmasi}>
            <div className="form-group">
              <label className="control-label">Order ID <span className="required">*</span></label>
              <div className="frame_no_pesanan">
                <input type="text" name="no_pesanan" readonly="readonly" className="form-control no_pesanan" maxlength="10" onKeyPress={fieldOnlyStringAndNumber.bind(this)} defaultValue={order_id} placeholder="No. Pesanan" required="required"/>
                <a className="btn btn-primary cek_no_pesanan">Cari</a>
              </div>
              <small className="warn-order-not-found">Order tidak ditemukan</small>
            </div>
            <div className="area-if-exist" style={{'display' : 'none'}}>
              <div className="form-group">
                <label className="control-label">Nama Pemilik Rekening <span className="required">*</span></label>
                <input type="text" name="atas_nama_pemilik_rekening" disabled="disabled" defaultValue={items.atas_nama} maxlength="50" onKeyPress={fieldOnlyString.bind(this)} className="form-control atas_nama_pemilik_rekening" placeholder="Pemilik Rekening" required="required"/>
              </div>
              <div className="form-group">
                <label className="control-label">Transfer ke <span className="required">*</span></label>
                <select name="rekening_tujuan" className="form-control rekening_tujuan" disabled="disabled" required="required">
                  <option value="0">-- Silahkan Pilih Rekening --</option>
                  <option value="1">BCA - Bagas Pramudita - 5315119925</option>
                  <option value="1">Mandiri - Bagas Pramudita - 9000010527001</option>
                </select>
              </div>
              <div className="form-group">
                <label className="control-label">Tanggal Transfer <span className="required">*</span></label>

                <div style={{'display' : 'block'}}>
                  <DatePicker className="form-control tgl_transfer" name="tgl_transfer" selected={startDate} onChange={date => setStartDate(date)} placeholderText="Pilih Tanggal" minDate={new Date().minDays(7)} maxDate={new Date()}/>
                </div>
                
              </div>
              <div className="form-group">
                <label className="control-label">Jumlah Transfer <span className="required">*</span></label>
                <input type="number" name="jumlah_tf" disabled="disabled" defaultValue={items.grand_total_with_kodeunik_no_format} className="form-control jumlah_tf" placeholder="123456" required="required"/>
              </div>
              <div className="form-group">
                <label className="control-label">Bukti Transfer</label>
                <input type="file" name="bukti_tf" disabled="disabled" className="form-control bukti_tf" accept="image/*"/>
              </div>
              <div className="form-group">
                <input type="submit" name="konfirmasi_pembayaran" disabled="disabled" value="Konfirmasi Pembayaran" className="btn btn-primary konfirmasi_pembayaran"/>
              </div>
            </div>
          </form>
        </div>
    </div>
  );
}

function ProductDetil(parameters) {
  

  state.menuOpen = false;
  // console.log('Detil');

  let urlGetListProduct = baseUrl + 'get_product_detil.php';
  const secretHash = hmacSHA256(urlGetListProduct, buildKey).toString();
  const apiUrl =  urlGetListProduct + '?apikey=' + secretHash + '&product=' + parameters.produk_alias;

  if(typeof parameters.produk_alias === 'undefined'){
    Moved.gO('/');
  }

  //console.log('produk_alias', parameters.produk_alias);

  const [items, setItems] = React.useState({});
  const [productExist, setProductExist] = React.useState(String);
  // setStatus(true);

  async function fetchData() {
    var data = await fetch(apiUrl).then(res => {
      //console.log(res.clone().json());
      isCreatedCallback = false;
      return res.clone().json();
    });
    //console.log(data);
    // eslint-disable-next-line no-const-assign
    setProductExist(data.status_product);
    setItems(data.product_detil);
    // console.log('items',items);
  }
  
  React.useEffect(() => {
    fetchData();
  }, [apiUrl]);

  console.log('productExist',productExist);

  hargaJualTotal = items.hargajual;
  subHargaJualTotal = items.hargajual;
  hargaJualTotalNoFormat = items.hargajual_no_format;

  const diskon_tambahan = formatRupiah('0', 'Rp');
  var kode_unik_formatted = formatRupiah('0', 'Rp');

  hargaJualTotalWithoutKodeUnik = parseInt(items.hargajual_no_format);
  TotalKodeUnik = parseInt(items.kodeunik_no_format);

  reCall();
  // updateQtyButton();
  reCountingOrders();
  
  $('.payment-selector').on('click', function(){
    $('input[type="radio"]').attr('checked', false);
    $('.payment-selector').removeClass('active');
    $(this).addClass('active');
    $(this).find('input[type="radio"]').attr('checked', true);
  });

  const timerCok = window.setInterval(function(){
    //console.log(items);

    if(productExist === 'product_not_found'){
      $('.page__section').hide();
      $('#app_buy_form').html('');
      $('.product-not-found').show();
      clearInterval(timerCok);
    }

    if(items.length <= 0){

      $('.grid-product').html('<div>Sedang Memuat Konten</div>');
      
    }else{
      clearInterval(timerCok);

      
      if(typeof items.title !== 'undefined'){
        //document.title = items.title;
        ReactPixel.track('AddToCart', {
          value: items.hargajual_no_format,
          currency: 'IDR'
        });
        ReactPixel.track('PageView', {});
        
        $('.list-product-buy-loader').hide();
        $('.list-product-buy').show();
      }
      
      if(!isCreatedCallback){
        $('.add').on('click', function () {
          console.log('Nambah');
          if ($(this).prev().val() < 1000) {
            $(this).prev().val(+$(this).prev().val() + 1);
          }
        });
        $('.sub').on('click',function () {
            if ($(this).next().val() > 1) {
              if ($(this).next().val() > 1) $(this).next().val(+$(this).next().val() - 1);
            }
        });

        updateQtyButton();
        isCreatedCallback = true;
      }

      // console.log('KodeUnik', parseInt(items.kodeunik).toString());

      kode_unik_formatted = formatRupiah(parseInt(items.kodeunik).toString(), 'Rp');
      $('.kode_unik_formatted').html(kode_unik_formatted);
      
      //console.log('items.upselling', items.upselling);

      if(typeof items.upselling !== 'undefined'){
        if(items.upselling.length <= 0){
          $('.parent-area-upselling').hide();
        }
      }

      var htmlListBonus = '';
      var totalPriceListBonus = 0;
      var totalBonusExist = 0;

      $.each(items.list_bonus, function(index, value){
        totalPriceListBonus += parseInt(value.price_no_format);
        totalBonusExist += 1;
        htmlListBonus += `
        <li>✅ <span class="bonus-item-name"> `+value.name+`</span> (Senilai `+value.price+`)</li>
        `;
      });

      if(totalBonusExist > 0){
        $('.bonus-information-area').show();
      }else{
        $('.bonus-information-area').hide();
      }

      $('.list-bonus').html(htmlListBonus);
      $('.total-bonus').html(formatRupiah(totalPriceListBonus.toString(), 'Rp'));

      var htmlUpselling = '';
      $.each(items.upselling, function( index, value ) {

        var imagesProd = value.image;
        htmlUpselling += `<div class="child identity_up_`+value.image_name+`">
                              <div class="promo-percentage">
                                HEMAT `+value.upselling_percentage+`%
                              </div>
                                  <a href="`+value.link_landingpage+`" target="_blank">
                                    <img src="`+imagesProd+`" alt="`+value.produk+`"/>
                                  </a>
                                  <button class="buy_this_to" from-element="identity_up_`+value.image_name+`" data-upselling="`+btoa(JSON.stringify(value))+`">Beli Ini Juga</button>
                                  <h3><a href="`+value.link_landingpage+`" target="_blank">`+value.produk+`</a></h3>
                                  <h4>`+value.harga_produk+`</h4>
                                  <strong>`+value.upselling_price+`</strong>
                            </div>`;
      });

      $('.grid-product').html(htmlUpselling);

      var $width_child = Math.round($('.frame-grid-product').width());
      var $width_parent = Math.round($('.grid-product').width());
      if(parseInt($width_child) < parseInt($width_parent)){
        $('.ke_kanan').show();
      }


      $('.buy_this_to').on('click', function(event){
        event.preventDefault();

        var $this_buy_this_to = $(this);
        var $parens_buy_this = $(this).parents('.child');
        var $upselling = $(this).data('upselling');
        $upselling = JSON.parse(atob($upselling));

        // console.log('$upselling', JSON.parse(atob($upselling)));
        var imagesProd = $upselling.image;

        //console.log('Masok Pak Eko');
        var $clone = $('.child-list-product-buy:last-child').clone();
        $clone.appendTo('.list-product-buy');
        $clone.find('.input_qty').val('1');
        $clone.find('.title-and-price').find('h3').html($upselling.produk + ' <span class="qtySummary_'+$upselling.image_name+'">x1</span>');
        $clone.find('.title-and-price').find('h2').html($upselling.harga_produk);
        $clone.find('.title-and-price').find('h4').html($upselling.upselling_price);

        $clone.find('.hidden_id').attr('name','id['+$upselling.image_name+']').val($upselling.upselling_id);
        $clone.find('.hidden_type').attr('name','type['+$upselling.image_name+']').val('upselling');
        $clone.find('.sub_total_price').attr('name','sub_total_price['+$upselling.image_name+']');
        $clone.find('.input_qty').attr('name','qty['+$upselling.image_name+']');

        $clone.find('.thumbnail').find('img').attr('src', imagesProd);
        $clone.find('.btn-decrease').attr('data-hargajual',$upselling.upselling_price_no_format);
        $clone.find('.btn-increase').attr('data-hargajual',$upselling.upselling_price_no_format);

        $clone.find('.sub_total_price').val($upselling.upselling_price_no_format);
        $clone.attr('product_name', $upselling.produk);
        $clone.attr('hargajual', $upselling.upselling_price_no_format);
        $clone.attr('hargaasli', $upselling.harga_produk_no_format);

        $clone.find('button').removeAttr('disabled');
        $clone.find('input').removeAttr('readonly');

        $clone.find('.custom-field').remove();
        $clone.find('.area_jangan_gunakan_email_pribadi').remove();

        var $trigclass_decrease = $clone.find('.btn-decrease').attr('trigclass');
        var $trigclass_increase = $clone.find('.btn-increase').attr('trigclass');

        if(typeof $trigclass_decrease !== 'undefined'){
          $clone.find('.btn-decrease').removeClass($trigclass_decrease).addClass('sub-'+$upselling.image_name).attr('trigclass', 'sub-'+$upselling.image_name);
        }else{
          $clone.find('.btn-decrease').removeClass('sub').addClass('sub-'+$upselling.image_name).attr('trigclass', 'sub-'+$upselling.image_name);
        }

        if(typeof $trigclass_increase !== 'undefined'){
          $clone.find('.btn-increase').removeClass($trigclass_increase).addClass('add-'+$upselling.image_name).attr('trigclass', 'add-'+$upselling.image_name);  
        }else{
          $clone.find('.btn-increase').removeClass('add').addClass('add-'+$upselling.image_name).attr('trigclass', 'add-'+$upselling.image_name);  
        }       

        $('.add-'+$upselling.image_name).on('click', function () {
          if ($(this).prev().val() < 1000) {
            $(this).prev().val(+$(this).prev().val() + 1);
          }

          var $parent = $(this).parents('.child-list-product-buy');
          $parent.find('.qtySummary_'+$upselling.image_name).html('x'+$(this).prev().val());
        });
        $('.sub-'+$upselling.image_name).on('click',function () {
            if ($(this).next().val() > 1) {
              if ($(this).next().val() > 1) $(this).next().val(+$(this).next().val() - 1);
            }

            var $parent = $(this).parents('.child-list-product-buy');
            $parent.find('.qtySummary_'+$upselling.image_name).html('x'+$(this).next().val());
        });

        $clone.find('.moveToTrash').attr('from-element', $this_buy_this_to.attr('from-element'));
        $parens_buy_this.hide();
        
        reCall();
        updateQtyButton();
        reCountingOrders();
      });


      reCountingOrders();

    }
  }, 1000);
  

  var vars = [], hash;
  var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
  for(var i = 0; i < hashes.length; i++)
  {
      hash = hashes[i].split('=');
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
  }

  setTimeout(() => {
    if(typeof vars != 'undefined'){
      if(typeof vars["kupon"] != 'undefined'){
        if(vars["kupon"].length > 0){
          $('.kupon_kode').val(vars["kupon"]);
          if(vars["kupon"].length > 3){
            $('.btnApplyCoupon').addClass('btn-success').removeClass('btn-secondary');
            applyCoupon($('input[name="kupon_kode"]'));
          }else{
            $('.btnApplyCoupon').removeClass('btn-success').addClass('btn-secondary');
          }
        }
      }
    }
  }, 1000);
  
  // console.log('vars', vars);

  $('.kupon_kode').on('keyup', function(){
    if($('input[name="kupon_kode"]').val().length > 3){
      $('.btnApplyCoupon').addClass('btn-success').removeClass('btn-secondary');
    }else{
      $('.btnApplyCoupon').removeClass('btn-success').addClass('btn-secondary');
    }
  });
  
  $('.btnApplyCoupon').on('click', function(event){
    event.preventDefault();

    //console.log('ApplyCoupon', 'Triggered');

    if($('input[name="kupon_kode"]').val().length > 3){
      applyCoupon($('input[name="kupon_kode"]'));
    }
    
  });

  // document.title = 'Beli Software & Topup Game Termurah di Indonesia';
  document.title = "Keranjang Belanja - RajaLisensi.com";
  const NotEmptyData = ((items.length > 0) ? true : false);


  const search = window.location.search;
  const params = new URLSearchParams(search);

  const utm_source = params.get('utm_source');
  const utm_medium = params.get('utm_medium');
  const utm_campaign = params.get('utm_campaign');

  $(document).ready(function(){
    $('.utm_source').val(utm_source);
    $('.utm_medium').val(utm_medium);
    $('.utm_campaign').val(utm_campaign);
    $('.btn-tg-official').on('click', function(e){
      e.preventDefault();

      window.open('https://chat.whatsapp.com/FnbK68DwbM2BYlP25JM3o9', '_blank');

    });

    $('.ke_kiri').on('click', function(){
      $('.frame-grid-product').animate( { scrollLeft: '-=100' }, 500);
    });
    $('.ke_kanan').on('click', function(){
      $('.ke_kiri').show();
      $('.frame-grid-product').animate( { scrollLeft: '+=100' }, 500);
    });
    $('.frame-grid-product').scroll( function() {
        var $width = Math.round($('.frame-grid-product').outerWidth());
        var $width_child = Math.round($('.frame-grid-product').width());
        var $width_parent = Math.round($('.grid-product').width());
        var $scrollWidth = Math.round($('.grid-product')[0].scrollWidth); 
        var $scrollLeft = $('.frame-grid-product').scrollLeft();

        // console.log('$width_child ', $width_child);
        // console.log('$width_parent ', $width_parent);

        if ($scrollWidth - $width === $scrollLeft){
          $('.ke_kanan').hide();
        }
        if ($scrollLeft===0){
          $('.ke_kiri').hide();
          if(parseInt($width_child) < parseInt($width_parent)){
            $('.ke_kanan').show();
          }
        }
    });
    $(".jangan_gunakan_email_pribadi").on('change', function(){
      var $parents = $(this).parents(".title-and-price");

      if($(".jangan_gunakan_email_pribadi").is(':checked')){
        $(".parent_jangan_gunakan_email_pribadi").show();
        $(".parent_jangan_gunakan_email_pribadi").find(".custom-field-input").val("");
      
        var biaya_layanan = 0;
        $('.biaya-layanan').hide();
        $('.biaya-layanan-value').html(formatRupiah(biaya_layanan.toString(), "Rp."));

        reCountingOrders(0);
      }else{
        $(".parent_jangan_gunakan_email_pribadi").hide();
        $(".parent_jangan_gunakan_email_pribadi").find(".custom-field-input").val("randominputdarinetfree");
      
        var biaya_layanan2 = 0;
        $('.biaya-layanan').hide();
        $('.biaya-layanan-value').html(formatRupiah(biaya_layanan2.toString(), "Rp."));


        reCountingOrders(0);
      }
    });
  });

  // if(items.length > 0){
    return (

      <div>
        <div className="product-not-found">
          <h2>Produk Tidak Ditemukan / Stok Habis</h2>
          <p>Mohon maaf, kami tidak dapat memuat produk yang anda tuju, hal ini juga bisa disebabkan karena stok produk habis (out of stock).</p>
        </div>
        <div className="page__section" style={{marginTop: (items?.title?.toLowerCase()?.includes("adobe creative cloud (1 tahun)") || items?.title?.toLowerCase()?.includes("mcafee total protection (1 device, 10 years)")) ? '-10px' : '1em'}}>
          <nav className="breadcrumb breadcrumb_type5" aria-label="Breadcrumb" style={{display: (items?.title?.toLowerCase()?.includes("adobe creative cloud (1 tahun)") || items?.title?.toLowerCase()?.includes("mcafee total protection (1 device, 10 years)")) ? 'none' : 'block'}}>
            <ol className="breadcrumb__list r-list">
              <li className="breadcrumb__group">
                <a href="https://rajalisensi.com" className="breadcrumb__point r-link"><FontAwesomeIcon icon={faHome} style={{'width' : '15px', 'height' : '15px', 'margin-left' : '0px', 'margin-right' : '7px', 'margin-top' : '3px', 'color' : '#333333'}}/> Beranda</a>
                <span className="breadcrumb__divider" aria-hidden="true">›</span>
              </li>
              <li className="breadcrumb__group">
                <a href={items.link_breadcrumb} target="_blank" className="breadcrumb__point r-link">{items.title}</a>
                <span className="breadcrumb__divider" aria-hidden="true">›</span>
              </li>
              <li className="breadcrumb__group">
                <span className="breadcrumb__point" aria-current="page">Checkout</span>
              </li>
            </ol>
          </nav>
        </div>  
        <div className="area-buy">
          <form name="app_buy_form" id="app_buy_form" action="" method="POST" onSubmit={handleSubmit}>
            <input type="hidden" name="token" value={items.token} className="token"/>
            <input type="hidden" name="kodeunik" value={items.kodeunik} className="kodeunik"/>
            <input type="hidden" name="utm_source" defaultValue="" className="utm_source"/>
            <input type="hidden" name="utm_medium" defaultValue="" className="utm_medium"/>
            <input type="hidden" name="utm_campaign" defaultValue="" className="utm_campaign"/>
            <div className="buy-left-area">
              <div className='reverse-in-mobile'>
                <div className="cont-area">
                  <div className="cont-header" style={{'border-bottom' : '1px solid #f1f1f1'}}>
                    <h2>Data Pembeli</h2>

                    <div className="right-secure-img">
                      <img src={seal_secure_img} alt="Transaksi Aman"/>
                      <img src={seal_satisfaction_img} alt="Jaminan Kepuasan"/>
                    </div>
                  </div>
                  <div className="cont-form">
                    <div className="child-cont-form">
                      <label className="control-label">ALAMAT EMAIL <span className="required_text">*</span></label>
                      <input type="email" name="email" onKeyPress={fieldOnlyEmail.bind(this)} className="email form-control" placeholder="google@gmail.com" maxlength="50" required="required"/>
                      <span className="notif-email">Lisensi Produk akan dikirim ke alamat email tersebut.</span>
                    </div>
                    <div className="child-cont-form">
                        <div className="sub-child-cont-form">
                          <label className="control-label">NAMA LENGKAP <span className="required_text">*</span></label>
                          <input type="text" name="nama_depan" onKeyPress={fieldOnlyString.bind(this)} className="nama_depan form-control" maxlength="50" placeholder="Contoh : Bambang" required="required"  /> 
                        </div>
                        <div className="sub-child-cont-form">
                          <label className="control-label">NOMOR WHATSAPP <span className="required_text">*</span></label>
                          <input type="text" name="no_wa" onKeyPress={fieldOnlyNumber.bind(this)} className="no_wa form-control" maxlength="15" placeholder="62812..." required="required"  />
                        </div>
                    </div>
                  </div>
                </div>
                <div className="cont-area" style={{padding: items?.title?.toLowerCase()?.includes("adobe creative cloud (1 tahun)") ? '0px' : '20px', marginTop: (items?.title?.toLowerCase()?.includes("adobe creative cloud (1 tahun)") || items?.title?.toLowerCase()?.includes("mcafee total protection (1 device, 10 years)")) ? '10px' : '0px','padding-left' : '0px', 'padding-right' : '0px'}}>
                  <div className="cont-header" style={{'padding-left' : '20px', 'padding-right' : '20px', display: items?.title?.toLowerCase()?.includes("adobe creative cloud (1 tahun)") ? 'none' : 'block'}}>
                    <h2>Pembelian Anda</h2>
                  </div>
                  <div className="cont-form" style={{marginTop: items?.title?.toLowerCase()?.includes("adobe creative cloud (1 tahun)") ? '0px' : '20px'}}>

                    <ReactPlaceholder className="list-product-buy-loader" type='text' rows={4} ready={false} style={{'padding-left' : '20px', 'padding-right' : '20px'}}></ReactPlaceholder>

                    <div className="list-product-buy" style={{'display' : 'none'}}>
                      <div className="child-list-product-buy" alias={items.alias} hargaasli={items.hargaasli_no_format} hargajual={items.hargajual_no_format} product_name={items.title}>
                        <input type="hidden" name={"id["+items.alias+"]"} className="hidden_id" value={items.id}/>
                        <input type="hidden" name={"type["+items.alias+"]"} className="hidden_type" value="bump"/> 
                        <input type="hidden" name={"sub_total_price["+items.alias+"]"} className="sub_total_price" value={items.hargajual_no_format}/>
                        <div className="thumbnail">
                          <img src={items.image} alt={items.title}/>
                        </div>
                        <div className="title-and-price">

                            <h3>{items.title} <span className={"qtySummary_" + items.alias}>x1</span></h3>
                            <h2 style={{'text-decoration' : 'line-through', 'font-size' : '15px', 'color' : '#ab0000'}}>{items.hargaasli}</h2>
                            <h4>{items.hargajual}</h4>

                            {(() => {
                            if (parseInt(items.total_product_custom) > 0) {
                              if(items.title.toLowerCase().includes("netflix")){
                                return (
                                  <div>
                                    <label className="toggle area_jangan_gunakan_email_pribadi">
                                      <input className="toggle-checkbox jangan_gunakan_email_pribadi" name="jangan_gunakan_email_pribadi" type="checkbox"/>
                                      <div className="toggle-switch"></div>
                                      <span className="toggle-label">Gunakan Email Pribadi </span><span className="tooltip-ex"><FontAwesomeIcon icon={faExclamationCircle} style={{'width' : '15px'}} /></span>
                                      <span className="tooltip-ex-text tooltip-ex-top">Jika Anda tidak ingin menggunakan email sendiri. Maka, kami akan membuatkan email baru khusus untuk akun Netflix Anda.</span>
                                    </label>
                                    <div className="custom-field parent_jangan_gunakan_email_pribadi" style={{'display':'none'}}>
                                      <span style={{'font-size' : '14px', 'color' : '#4c4c4c'}}>Email Anda</span>
                                      <input type="text" name={"custom-field-input["+items.alias+"]"} defaultValue="randominputdarinetfree" onKeyPress={fieldOnlyEmail.bind(this)} className="form-control custom-field-input" placeholder="Email Anda" required="required" maxLength="50" style={{'margin-top' : '4px', 'margin-bottom' : '3px'}}/>
                                      <span style={{'font-size' : '11px','color' : '#5f5f5f','margin-left' : '2px;'}}>*) Pastikan email yang anda masukkan belum terdaftar di Netflix sebelumnya.</span>
                                      <span style={{'font-size' : '11px','color' : '#5f5f5f','margin-left' : '2px;'}}>*) Disarankan agar Anda menggunakan alamat Gmail, jangan gunakan selain Gmail.</span>
                                    </div>
                                  </div>
                                );
                              
			      }else if(items.title.toLowerCase().includes("adobe creative cloud (1 tahun)")){
                              return (
                                <div className="custom-field" style={{marginBottom : '0px', marginTop: '15px'}}>
                                  <span style={{'font-size' : '14px', 'color' : '#4c4c4c'}}>Email yang ingin anda daftarkan</span>
				  <input type="text" name={"custom-field-input["+items.alias+"]"} onKeyPress={fieldOnlyEmail.bind(this)} className="form-control custom-field-input w-100-mobile" defaultValue="" placeholder="emailanda@gmail.com" required="required" minlength="6" maxLength="50" style={{'margin-top' : '4px', 'margin-bottom' : '3px'}}/>
                                  {/* <span style={{'font-size' : '11px','color' : '#5f5f5f','margin-left' : '2px;'}}>ex: bambang@gmail.com</span> */}
                                  <span style={{'font-size' : '11px','color' : '#5f5f5f','margin-left' : '2px;'}}>*) Disarankan agar Anda menggunakan alamat Gmail</span>
                                </div>
                              );
                            }else{
                                return (
                                  <div className="custom-field">
                                    <span style={{'font-size' : '14px', 'color' : '#4c4c4c'}}>Username Office</span>
                                    <input type="text" name={"custom-field-input["+items.alias+"]"} onKeyPress={fieldOnlyStringAndNumber.bind(this)} className="form-control custom-field-input" defaultValue="" placeholder="Custom Username" required="required" minlength="6" maxLength="15" style={{'margin-top' : '4px', 'margin-bottom' : '3px'}}/>
                                    <span style={{'font-size' : '11px','color' : '#5f5f5f','margin-left' : '2px;'}}>ex: bambang@365office.com</span>
                                  </div>
                                );
                              }
                              
                            }
                            })()}
                            
                        </div>
                        <div className="action-button" style={{display: items?.title?.toLowerCase()?.includes("adobe creative cloud (1 tahun)") ? 'none' : 'inline-block'}}>
                          <div className="frame-trash">
                            <button className="btn btn-danger moveToTrash">
                              <FontAwesomeIcon icon={faTrash} style={{'width' : '11px'}} />
                            </button>
                          </div>


                          {(() => {
                            if (parameters.produk_alias === 'office-365-custom') {
                              return (
                                <div className="frame-increase-decrease">
                                    <button disabled="disabled" data-hargajual={items.hargajual_no_format} data-kodeunik={items.kodeunik_no_format} className="btn-decrease sub" type="button" id="sub">-</button>
                                    <input readonly="readonly" className="input_qty" name={"qty["+items.alias+"]"} type="text" id="1" defaultValue="1"  />
                                    <button disabled="disabled" data-hargajual={items.hargajual_no_format} data-kodeunik={items.kodeunik_no_format} type="button" id="add" className="btn-increase add">+</button>
                                </div>
                              );
                            }else{
                              return (
                                <div className="frame-increase-decrease">
                                    <button data-hargajual={items.hargajual_no_format} data-kodeunik={items.kodeunik_no_format} className="btn-decrease sub" type="button" id="sub">-</button>
                                    <input className="input_qty" name={"qty["+items.alias+"]"} type="text" id="1" defaultValue="1"  />
                                    <button data-hargajual={items.hargajual_no_format} data-kodeunik={items.kodeunik_no_format} type="button" id="add" className="btn-increase add">+</button>
                                </div>
                              );
                            }
                          })()}


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {((items?.title?.toLowerCase()?.includes("adobe creative cloud (1 tahun)") || items?.title?.toLowerCase()?.includes("mcafee total protection (1 device, 10 years)"))) ? <></> : 
              <div className="bonus-information-area" style={{'display' : 'none'}}>
                  <h3>🔥 Bonus Spesial Khusus Untuk Anda!</h3>
                  <p>Dengan pembelian produk <strong>{items.title}</strong>, maka anda <strong>BERHAK</strong> untuk mendapatkan <strong>BONUS</strong> berikut: </p>
                  <ul className="list-bonus"></ul>
                  <h2>TOTAL BONUS SENILAI <span className="total-bonus">RP700.000</span></h2>
                  <h4><span style={{'color' : 'red'}}>Catatan:</span> Bonus Hanya Diberikan Jika Anda Melakukan Pembayaran Hari Ini Juga</h4>
              </div>}
              
              <div className="cont-area parent-area-upselling">
                <div className="cont-header">
                  <h2>Beli Banyak Produk, Lebih Hemat</h2>
                </div>
                <div className="frame-parent-grif-product">
                  <a className="ke_kiri">
                    <FontAwesomeIcon icon={faAngleLeft} style={{'width' : '50px', 'height' : '50px'}} />
                  </a>
                  <div className="cont-form frame-grid-product">
                    <div className="grid-product">
                      {/* Digenerate Jin */}
                      <ReactPlaceholder type='text' rows={3} ready={false}>
                      </ReactPlaceholder>
                    </div>
                  </div>
                  <a className="ke_kanan">
                    <FontAwesomeIcon icon={faAngleRight} style={{'width' : '50px', 'height' : '50px'}} />
                  </a>
                </div>
               
              </div>
            </div>
            <div className="buy-right-area">
              <div className="cont-area">
                <div className="cont-header">
                  <h2>Rincian Pesanan</h2>
                  <hr/>
                </div>
                <div className="cont-form" style={{'display': 'none'}}>
                  <div className="frame-payment-selector">
                    <div className="payment-selector active" style={{'display' : 'none'}}>
                      <div className="left">
                        <img src={payment_gabungan} alt={"Bank Mandiri"}/>
                      </div>
                      <div className="center">
                          <h2>Transfer Bank</h2>
                          <small>Diverifikasi Otomatis</small>
                      </div>
                      <div className="right">
                          <input type="radio" name="payment_chooser" defaultValue="1" className="radio_payment_Selector"/>
                          <input type="radio" name="payment_chooser" checked="checked" defaultValue="4" className="radio_payment_Selector"/>
                      </div>
                    </div>
                    <div className="payment-selector" style={{'display' : 'none'}}>
                      <div className="left">
                        <img src={payment_gopay} alt={"GoPay"}/>
                      </div>
                      <div className="center">
                          <h2>GOPAY</h2>
                          <small>DIVERIFIKASI OTOMATIS</small>
                      </div>
                      <div className="right">
                          <input type="radio" name="payment_chooser" defaultValue="3" className="radio_payment_Selector"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-summary-right">
                  <div className="cont-form" style={{'marginTop': '0px'}}>
                    <div className="parent-summary-cont-form">
                      {/* <!-- Digenerate Jin --> */}
                      <ReactPlaceholder type='text' rows={2} ready={false}>
                    </ReactPlaceholder>
                    </div>
                  </div>
                  <div className="cont-form">
                    <div className="summary-area">
                        <div className="child-summary biaya-layanan" style={{'display' : 'none'}}>
                            <strong className="left">
                              Biaya Layanan
                            </strong>
                            <strong className="right biaya-layanan-value">
                              {/* Digenerate Jin */}
                            </strong>
                        </div>
                        <div className="child-summary">
                            <strong className="left">
                              Sub Total
                            </strong>
                            <strong className="right sub-total-payment">
                              {subHargaJualTotal}
                            </strong>
                            
                        </div>
                        <div className="child-summary diskon" style={{'display' : 'none'}}>
                            <strong className="left">
                              Diskon
                            </strong>
                            <strong className="right">
                              {diskon_tambahan}
                            </strong>
                        </div>
                        <div className="child-summary" style={{'display' : 'none'}}>
                            <strong className="left">
                              Kode Unik
                            </strong>
                            <strong className="right kode_unik_formatted">
                              {kode_unik_formatted}
                            </strong>
                        </div>
                    </div>
                    <div className="grandtotal-area">
                      <strong className="left">
                        Grand Total
                      </strong>
                      <strong className="right total-payment">
                        {hargaJualTotal}
                      </strong>
                      <strong className="total-payment-no-format" style={{'display':'none'}}>
                        {hargaJualTotalNoFormat}
                      </strong>
                    </div>
                  </div>
                </div>
                <div className="cont-form submit-area-checkout">
                  <button type="submit" name="process_checkout"  className="btn btn-primary btn_process_checkout" value="Lanjutkan Pembayaran">
                    <FontAwesomeIcon className="onLoading" icon={faSpinner} pulse style={{'width' : '14px', 'margin-right' : '10px', 'display' : 'none'}}/>
                    <FontAwesomeIcon className="onNormal" icon={faShoppingCart} style={{'width' : '14px', 'margin-right' : '10px'}} /> 
                    <span className="onClickSubmitText">Lanjutkan Pembayaran</span>
                  </button>
                  <div className="metode-bayar">
                      <div className="p-m-bayar">Metode Pembayaran</div>
                      <div className="img-bayar">
                        <img src="https://rajalisensi.com/wp-content/uploads/2020/05/metode-bayar-min.png" border="0"/>
                      </div>
                  </div>
                </div>
                <div className="cont-form">
                  <div className="header-kode-kupon">
                      <h3>GUNAKAN KODE KUPON</h3>
                  </div>
                  <div className="parent-kode-kupon">
                      <input type="text" name="kupon_kode" placeholder="Contoh: HEMAT20" data-offers_id={items.id} className="form-control kupon_kode"/>
                      <button className="btn btn-secondary btnApplyCoupon">Apply</button>
                  </div>
                  <div className="alert alert-danger kupon-failed">
                        <strong>Kupon yang Anda masukan tidak valid.</strong>
                  </div>
                  <div className="alert alert-success kupon-success">
                        <strong className="result_kupon">Kupon yang Anda masukan tidak valid.</strong>
                  </div>
                </div>
                <div className="box-white box-bantuan-shopping-cart">
                  <div className="help-cart">
                    <div className="left-info">
                      <img src={support_png} alt="Bantuan - RajaLisensi.com"/>
                    </div>
                    <div className="right-info">
                      <h1>Bantuan</h1>
                      <p>Hubungi <a href="https://wa.rajalisensi.com" target="_blank">WhatsApp</a> atau
                      email ke <a href="mailto:info@mail.rajalisensi.com">info@mail.rajalisensi.com</a> jika terjadi
                      kendala dalam proses belanja.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cont-area" style={{"background" : "#3c7d5b", "color" : "#fff", "min-height" : "auto", "display" : "flex", "justifyContent" : "flex-start", "gap" : "20px"}}>
                  <div className="tg-img">
                    <img src="https://rajalisensi.com/wp-content/uploads/2020/11/waloga-min.png" border="0"/>
                  </div>
                  <div className="tg-hd">
                      <div className="tg-head1">Mau Software Gratis?</div>
                      <div className="tg-desc">Dapatkan update software gratis dan penawaran spesial hanya melalui Grup WhatsApp kami.</div>
                      <div className="tg-btn">
                        <a href="https://chat.whatsapp.com/FnbK68DwbM2BYlP25JM3o9" target="_blank">
                          <button className="btn-tg-official">Join Sekarang</button>
                        </a>
                      </div>
                  </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  
}

function showSettings (event) {
  event.preventDefault();
  
}

const App = () => (
  <Router>
    <ScrollToTop>
      <div className="top-header-bar">
        <div className="fill-content">
          <div className="left-content">
              <a href="https://rajalisensi.com/">
                <LogoHeader width={212}/>
              </a>
          </div>
          <div className="menu-burger-mobile">
            <Menu right disableAutoFocus isOpen={state.menuOpen} onStateChange={(state) => handleStateChange(state)}>
              <Link to={'/produk/microsoft-365'} onClick={changeIsMenuOpen} className="menu-item" id="microsft-365">Microsoft 365</Link>
              <Link to={'/produk/windows-10-pro'} onClick={changeIsMenuOpen} className="menu-item" id="windows-10-pro">Windows 10 Pro</Link>
              <Link to={'/produk/project-professional'} onClick={changeIsMenuOpen} className="menu-item" id="project-pro-2019">Project Pro 2019</Link>
              <Link to={'/produk/visio-professional'} onClick={changeIsMenuOpen} className="menu-item" id="visio-pro-2019">Visio Pro 2019</Link>
              <Link to={'/produk/idm'} onClick={changeIsMenuOpen} className="menu-item" id="internet-download-manager">Internet Download Manager</Link>
              <Link to={'/produk/avg'} onClick={changeIsMenuOpen} className="menu-item" id="avg-internet-security">AVG Intenet Security 2020</Link>
              <Link to={'/produk/bitdefender'} onClick={changeIsMenuOpen} className="menu-item" id="bitdefender-internet-security">Bitdefender Internet Security 2020</Link>
              <a href="https://rajalisensi.com/wa-sender/?utm_source=RL&utm_medium=Home" target="_blank" className="menu-item" id="wa-sender-ultimate">WA Sender Ultimate</a>
              <a href="https://rajalisensi.com/wp-rocket/?utm_source=RL&utm_medium=Home" target="_blank" className="menu-item" id="wp-rocket-plugin">WP Rocket Plugin</a>
            </Menu>
          </div>
          <div className="right-content">
            <div className="right-tom">
              <div className="tanya-admin">Tanya Admin Yuk!</div> 
              <span className="pulse"></span>
                <div className="concs">
                  {/* <a className="trigger-crisp" onClick={openChat.bind(this)} style={{'cursor' : 'pointer'}}>
                    <img src="https://rajalisensi.com/wp-content/uploads/2019/03/wa.png" alt="Homepage 2" />
                  </a> */}
                  <a href='https://wa.rajalisensi.com/' target='_blank'>
                    <img src="https://rajalisensi.com/wp-content/uploads/2019/03/wa.png" alt="Homepage 2" />
                  </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-lg-12" style={{'padding' : '0px'}}>
          <Route exact path="/" component={Home} />
          <Route path="/produk" component={NotFound} />
          <Route path="/produk/:produk_id" component={Produk} />
          <Route path="/upsel/:order_id" component={UpSelling} />
          <Route path="/downsel/:order_id" component={DownSelling} />
          <Route path="/finish/:order_id" component={FinishOrder} />
          <Route path="/midfinish" component={MidtransFinish} />
          <Route path="/miderror" component={MidtransFinish} />
          <Route path="/midunfinish" component={MidtransFinish} />
          <Route path="/konfirmasi" component={Konfirmasi} />
          <Route path="/konfirmasi_berhasil" component={FinishKonfirmasi} />
          <Route path="/select-payment/:order_id" component={SelectPayment} />
        </div>
      </div>
      <div className="app-footer-mobile">
        <div className="footer-title">
              Powered by
            <img src={logo_rl_png} className="img-fluid" alt="RajaLisensi.com"/>
          </div>
        <div className="footer-copyright">Copyright © 2024</div>
      </div>
      </ScrollToTop>
  </Router>
);

const NotFound = () => (
  <NotFoundPage />
);

const MidtransFinish = ({match}) => (
  <div className="container container-plans Mb20">
    <MidtransFinishView parameters={window.location.search.substring(1)} />
  </div>
);

const FinishKonfirmasi = () => (
  <FinishKonfirmasiView />
);

const SelectPayment = ({ match }) => (
  <div className="container container-plans Mb20">
      <SelectPaymentView order_id={match.params.order_id}/>
  </div>
);

const FinishOrder = ({ match }) => (
  <div className="no-container">
      <FinishSellingProducts order_id={match.params.order_id}/>
  </div>
);

const DownSelling = ({ match }) => (
  <div className="content">
    <div className="page-header">
      <h2 style={{'text-align': 'left'}}>
        <span>Kalo Ini Yakin Gak Mau?</span> 
        <small><Link className="goto-finish" to={{pathname: "/finish/" + match.params.order_id}}>Lanjutkan Pembayaran</Link></small>
      </h2>
    </div>
    <div className="col-lg-12 row">
      <ListDownsellinProducts order_id={match.params.order_id} />
    </div>
  </div>
);

const UpSelling = ({ match }) => (
  <div className="content">
    <div className="page-header">
      <h2>
        <small><Link className="not-interested" to={{pathname: "/downsel/" + match.params.order_id}}>Mau Lebih Murah</Link></small>
        <span>Lebih Murah</span> 
        <small><Link className="goto-finish" to={{pathname: "/finish/" + match.params.order_id}}>Lanjutkan Pembayaran</Link></small>
      </h2>
    </div>
    <div className="col-lg-12 row">
      <ListUpsellinProducts order_id={match.params.order_id} />
    </div>
  </div>
);

const Home = () => (
  <div className="content">
    <div className="page-header">
        <h2>Daftar Produk</h2>
    </div>
    <div className="col-lg-12 row" style={{'margin':'0px','padding' : '0px'}}>
      <ListProducts />
    </div>
  </div>
);

const Produk = ({ match }) => (
<div className="content">
  <ProductDetil produk_alias={match.params.produk_id} />
</div>
);

const Konfirmasi = () => (
  <div className="content">
    <KonfirmasiDetil order_id={0} />
  </div>
);


export default withRouter(App);
